const styles = {
  container: {
    label: 'DigitalHuman',
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(#DFF2F1, 100% ,#577188,100%, #01426A,100%)',
    '& a, & a:active, & a:focus, & button, & button:active, & button:focus': {
      outline: 'none'
    }
  },
  container1: {
    label: 'DigitalHuman',
    position: 'absolute',
    height: '100%',
    width: '100%',
    // background: 'linear-gradient(135deg, #2D373C 0%, #48BAAC 100%)',
    backdropFilter: 'blur(1.188rem)',
    '& a, & a:active, & a:focus, & button, & button:active, & button:focus': {
      outline: 'none'
    }
  },
  chatModalityToggle: {
    display: 'flex'
  },
  chatBtn: {
    position: 'absolute',
    top: '3vh',
    right: '2%',
    background: '#fff',
    borderRadius: '100%',
    padding: '0.625rem'
  },
  avatar: {
    label: 'avatar',
    height: 'auto',
    width: '100%'
  },
  localVideo: {
    width: 400,
    height: 300,
    display: 'none'
  },
  motionSuggestedResponses: {
    position: 'absolute',
    top: -100,
    right: 50,
    height: 500,
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '@media(orientation: landscape) and (max-width: 825px)': {
      height: 230
    }
    // '@media(max-width: 500px)': {
    //   top: -130
    // },
    //Galaxy Fold
    //'@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
    // top: -40
    //}
    // '@media(max-height: 700px)': {
    //   top: [-130, 1, 1, 1, 100, 110]
    // }
    // '@media(max-height: 700px)': {
    //   top: [-130, -40, -10, 1, 100, 110]
    // }s
  },
  // Check-in Button
  motionSuggestedResponses2: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    top: [0, 0, 0, 0, 0, -100],
    left: [0, 25, 25, 50],
    height: [260, 260, 260, 260, 300, 500]
  },
  settingsContainer: {
    label: 'settingsContainer',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    zIndex: 2,
    '& > svg': {
      fill: 'white'
    }
  },
  galleryOverlay: {
    left: 0,
    zIndex: 0,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  galleryOverlayGradient: {
    width: '50vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'linear-gradient(to right, transparent 0%, black 100%)',
    opacity: '50%'
  },
  galleryOverlayFilter: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'black',
    opacity: '30%'
  }
}

export default styles
