import React from 'react'
import { Text } from 'rebass'
import { FatalError } from 'uneeq-react-ui'
import { useTranslation } from 'react-i18next'

interface SessionTimedOutProps {
  restart: () => void
}
const SessionTimedOut: React.FC<SessionTimedOutProps> = ({ restart }) => {
  const { t, i18n } = useTranslation()
  return (
    <FatalError
      clearError={restart}
      errorTitle={t('SessionTimedOut.errortitle')}
    >
      <Text />
    </FatalError>
  )
}

export default SessionTimedOut
