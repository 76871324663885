const styles = {
  container: {
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 1s'
  },
  inner: {
    fontFamily: 'RocheSans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '1.4rem',
    color: '#FFFFFF',
    lineHeight: '1.875rem',
    textShadow: '0.063rem 0.063rem 0.125rem #000000',
    overflowY: 'auto',
    textAlign: 'center',
    borderRadius: '1.25rem',
    minHeight: [44, 44, 44, 44, 73, 73],
    // maxHeight: ["auto", "auto", "auto", "auto", "auto", "auto"],
    minWidth: 144,
    maxWidth: ['90%', '90%', '90%', '90%', '90%', '90%'],
    wordBreak: 'break-word',
    // overflow: [
    //   'scroll',
    //   'scroll',
    //   'ellipsis',
    //   'ellipsis',
    //   'ellipsis',
    //   'ellipsis'
    // ],
    pt: [4, 4, 4, 4, 10, 10],
    px: [6, 6, 6, 6, 10, 10],
    alignItems: 'center',
    justifyContent: 'center',
    width: '46.875rem',
    '& span': {
      minWidth: 'auto'
    },
    '@media(orientation: landscape) and (max-width: 825px)': {
      width: '100%',
      position: 'fixed',
      overflowY: 'scroll',
      overflowX: 'scroll',
      maxWidth: '90%',
      fontWeight: '300',
      fontSize: '0.7rem',
      bottom: '3.438rem',
      overflow: 'hidden'
    },
    '@media(orientation: landscape) and (min-width: 566px) and (max-width: 570px)': {
      bottom: '2.063rem'
    }
  }
}

export default styles
