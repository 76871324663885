const styles = {
  bar: {
    label: 'topBar',
    position: 'absolute',
    top: 0,
    height: '5rem',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 35,
    '& div::-webkit-scrollbar': {
      display: 'none'
    },
    '& div': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none'
    }
  },
  chatModalityToggle: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  pttOuterContainer: {
    justifyContent: 'center',
    minHeight: [40, 40, 70, 70, 50, 50],
    width: '100%'
  },
  topBarFlexContainer: {
    label: 'topBarFlexContainer',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%'
  },
  topBarFlexContainerDiana: {
    label: 'topBarFlexContainer',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    pb: '0.625rem'
  },
  topBarButtonContainer: {
    label: 'topBarButtonContainer',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    zIndex: 1
  },
  topBarMobileButtonContainer: {
    label: 'topBarMobileButtonContainer',
    alignItems: 'center',
    justifyContent: 'center',
    display: ['flex', 'flex', 'flex', 'none'],
    width: '100%',
    zIndex: 1
  },
  topBarSettingsContainer: {
    label: 'topBarSettingsContainer',
    zIndex: 2
  },
  topBarButton: {
    color: '#FFFFFF',
    zIndex: 10,
    background: 'none',
    borderRadius: '50%',
    fontFamily: 'RocheSans-Light',
    '@media(max-width: 768px)': {
      ml: 0
    },
    display: 'block'
  },
  topBarButtonChat: {
    width: '4rem',
    height: '4rem',
    '& :hover': {
      boxShadow: 'none'
    },
    '& > svg': {
      margin: 'auto',
      alignSelf: 'center',
      height: '2rem',
      width: '2rem'
    }
  },
  avatarIcon: {
    display: 'block',
    color: '#fff',
    width: '100%',
    height: '5rem',
    minHeight: '5rem',
    minWidth: '5rem',
    zIndex: 10000,
    cursor: 'pointer'
  },
  dianaFeedbackContainer: {
    minWidth: '5rem',
    width: '100%',
    minHeight: '5rem'
  }
}

export default styles
