import { closeDialogs, closeModals } from './initialState'
import { v4 as uuid } from 'uuid'
import {
  UneeqInformation,
  UneeqSuggestedResponses,
  UneeqState,
  UneeqAction,
  UneeqCheckLists,
  Config
} from '../../uneeq'

export type UneeqCommand = {
  openInputBar?: boolean
  openTranscript?: boolean
  openFeedback?: boolean
  openEscalationForm?: boolean
  information?: UneeqInformation
  action?: UneeqAction
  checkList?: UneeqCheckLists
} & UneeqSuggestedResponses

export const uneeqCommandReducer = (
  state: UneeqState,
  command: UneeqCommand,
  config: Config
) => {
  let newState = { ...state }
  if (command.openInputBar) {
    newState.inputMode = command.openInputBar ? 'text' : 'speech'
    newState.typeModeFromBackend = true
  }
  if (command.openTranscript) {
    newState = {
      ...newState,
      ...closeDialogs,
      transcriptOpen: command.openTranscript,
      transcriptHasOpened: state.transcriptHasOpened || command.openTranscript
    }
  }
  if (command.openFeedback) {
    newState = {
      ...newState,
      ...closeDialogs,
      ...closeModals,
      feedbackOpen: command.openFeedback
    }
  }
  if (command.openEscalationForm) {
    newState = {
      ...newState,
      ...closeDialogs,
      ...closeModals,
      escalationFormOpen: command.openEscalationForm,
      escalationFormFromServer: true
    }
  }

  if (command.action) {
    console.info('ACTION')
    console.info(JSON.stringify(command.action))
    newState.onScreenInfo.action = command.action
  } else {
    console.info('ACTION REMOVED')
    newState.onScreenInfo.action = undefined
  }
  console.info(command)
  if (command.information) {
    let normalizedInformation
    if (command.information[0]) {
      let info = command.information[0]
      let parsedInfo = JSON.stringify(info)
      parsedInfo = JSON.parse(parsedInfo)
      newState.transcript = [
        ...state.transcript,
        {
          information: [
            {
              type: parsedInfo['type'],
              source: parsedInfo['source'],
              index: parsedInfo['index'],
              background: parsedInfo['background']
            }
          ],
          time: new Date(),
          user: false
        }
      ]
    }
    if (typeof command.information === 'string') {
      normalizedInformation = [
        { type: 'markdown', markdown: command.information }
      ]
    } else {
      normalizedInformation = command.information
    }

    newState.onScreenInfo.information = normalizedInformation
  } else {
    newState.onScreenInfo.information = undefined
  }
  if (command.checkList) {
    let normalizedCheckList
    if (typeof command.checkList === 'string') {
      normalizedCheckList = [
        { type: 'markdown', markdown: command.information }
      ]
    } else {
      normalizedCheckList = command.checkList
    }
    // @ts-ignore
    newState.onScreenInfo.checkList = normalizedCheckList
    if (config.informationInTranscript) {
      newState.transcript = [
        ...state.transcript,
        {
          checkList: normalizedCheckList,
          user: false,
          time: new Date()
        }
      ]
    }
  } else {
    newState.onScreenInfo.checkList = undefined
  }

  if (command.suggestedResponses && command.suggestedResponses.length > 0) {
    const date = new Date()
    date.setSeconds(date.getSeconds() + 2)

    const groupid = uuid()
    command.suggestedResponses.forEach(element => {
      element['id'] = uuid()
      element['groupId'] = groupid
    })
    console.info('Unique ID')
    console.info(JSON.stringify(command.suggestedResponses))

    newState.transcript = [
      ...state.transcript,
      {
        type: 'media',
        information: [
          {
            type: 'buttons',
            buttons: command.suggestedResponses
          }
        ],
        time: date,
        user: false
      }
    ]
    newState.onScreenInfo.information = [
      {
        type: 'buttons',
        buttons: command.suggestedResponses
      }
    ]
    if (state.galleryOpen == true) newState.galleryOpen = false
  }
  return newState
}

export default uneeqCommandReducer
