export const styles = {
  mainContainer: {
    label: 'homeMainContainer',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      display: 'none'
    },
    '& *': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    minWidth: '100%',
    height: '100%',
    position: 'absolute',
    alignItems: 'flex-start',
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    justifyContent: [
      'flex-start',
      'flex-start',
      'flex-start',
      'flex-start',
      'normal',
      'normal'
    ],
    width: '100%',
    '& > video': {
      objectFit: 'cover',
      width: '100vw',
      height: [
        'calc(100vh - 3rem)',
        'calc(100vh - 3rem)',
        '100vh',
        '100vh',
        '100vh'
      ],
      minHeight: ['auto', 'auto', '100vh', '100vh', '100vh'],
      position: ['static', 'static', 'static', 'static', 'fixed', 'fixed'],
      top: 0,
      left: 0,
      zIndex: 0
    },
    maxHeight: '100%'
  },

  videoContainer: {
    my: 2,
    width: '28%',
    display: ['none', 'none', 'none', 'none', 'block', 'block']
  },
  textContainer: {
    label: 'text-container',
    mb: [4, 0],
    pl: ['10%', '10%', '10%', '10%', '15%', '15%'],
    pr: ['10%', '10%', '10%', '10%', '40%', '35%'],
    mt: 0,
    height: ['0.063rem', '0.063rem', '0.063rem', '0.063rem', 'auto', 'auto'],
    maxWidth: ['100%', '100%', '100%', '100%', '90%', '90%'],
    color: 'white',
    textAlign: 'left',
    zIndex: 4,
    width: '100%',
    maxHeight: '100%',
    overflowY: ['visible', 'visible', 'visible', 'visible', 'scroll', 'scroll']
  },

  subtitleText: {
    mt: '2.5rem',
    fontSize: '1.563rem',
    fontWeight: '700'
  },

  imSophieText: {
    display: ['flex', 'flex', 'flex', 'flex', 'flex', 'flex'],
    fontSize: [55, 55, 55, 55, 55, 65],
    lineHeight: '5rem',
    mt: 15,
    fontWeight: 'bold',
    fontFamily: 'RocheSans'
  },

  mobileImSophie: {
    display: ['flex', 'flex', 'flex', 'flex', 'none', 'none'],
    fontSize: [3, 3, 6, 6, 78, 78],
    lineHeight: '1.625rem',
    minHeight: 56,
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '1.25rem',
    width: '60%',
    fontFamily: 'RocheSans',
    //iPad
    '@media(min-width: 760px) and (max-width: 770px)': {
      fontSize: '1.313rem',
      marginTop: '1.25rem'
    },
    //Surface Duo
    '@media(min-width: 500px) and (max-width: 52.5rem)': {
      fontSize: '1.125rem',
      marginTop: '2.5rem'
    },
    //Pixel 2
    //Pixel 2 XL
    '@media(min-width: 408px) and (max-width: 418px)': {
      fontSize: '1.063rem',
      marginTop: '3.125rem'
    },
    //iPhone 6/7/8
    //iPhone X
    '@media(min-width: 374px) and (max-width: 377px) and (max-height: 814px)': {
      fontSize: '1.063rem',
      marginTop: '3.125rem'
    },
    //iPhone X landscape
    '@media(orientation: landscape) and (min-width: 374px) and (max-width: 377px), (orientation: landscape) and (max-height: 812px)': {
      fontSize: '1.063rem',
      marginTop: '3.125rem'
    },
    //iPhone 5/SE
    '@media(min-width: 318px) and (max-width: 322px)': {
      fontSize: '1.063rem',
      marginTop: '3.125rem'
    },
    //Galaxy S5
    '@media(min-width: 358px) and (max-width: 362px)': {
      fontSize: '1.063rem',
      marginTop: '3.125rem'
    },
    //Galaxy Fold
    '@media(min-width: 278px) and (max-width: 282px)': {
      fontSize: '1.063rem',
      marginTop: '3.125rem'
    },
    '@media(min-width: 425px) and (max-width: 430px)': {},
    '@media(orientation: landscape) and (min-width: 560px) and (max-width: 570px)': {
      fontSize: '1.063rem',
      marginTop: '3.125rem'
    },
    //Galaxy Fold landscape
    '@media(orientation: landscape) and (min-width: 63.125rem) and (max-width: 655px)': {
      fontSize: '1.063rem',
      marginTop: '3.125rem'
    }
  },

  subtitle: {
    paddingTop: '1.875rem',
    paddingBottom: '6.25rem',
    fontWeight: '300',
    fontSize: [5, 5, 5, 5, 5, 5],
    mt: 2,
    mb: [3, 4],
    lineHeight: '1.875rem',
    display: ['block', 'block', 'block', 'block', 'block', 'block'],
    maxWidth: 480,
    fontFamily: 'RocheSans'
  },

  sophieBGImage: {
    label: 'sophieBGImage',
    position: 'fixed',
    backgroundSize: ['100%', '100%', '70%'],
    backgroundRepeat: 'no-repeat',
    backgroundPosition: ['top', 'top', '100% bottom'],
    top: ['3em', '3em', 'unset'],
    right: ['3em', '3em', 'unset'], // this is bc the empty space the img has, we should ask them for a better png
    height: '100%',
    width: '100%',
    zIndex: 1
  },
  letsChatButton: {
    lineHeight: '2.5rem',
    fontWeight: '600',
    minWidth: ['7rem', '7rem', '7rem', '7rem', '7rem', '8rem'],
    backgroundColor: '#DC7D58',
    // border: 'none',
    border: '0.063rem solid #B1B3B3',
    fontSize: [2, 2, 2, 2, 2, 3],
    py: [2, 2, 2, 2, 2, 3],
    px: [1, 1, 1, 1, 1, 3],
    mt: 3,
    mb: 10
    // fontFamily: "Berthold Imago"
  },
  letsChatButton2: {
    lineHeight: '1.25rem',
    fontWeight: '100',
    width: 'auto',
    mb: '0.313rem',
    borderRadius: '0.625rem',
    backgroundColor: '#DC7D58',
    // border: 'none',
    border: '0.063rem solid #B1B3B3',
    fontSize: [2, 2, 2, 2, 2, 3]
    // fontFamily: "Berthold Imago"
  },
  privacyTextToggle: {
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: 0,
    lineHeight: '1.375rem',
    textDecoration: 'none',
    maxWidth: 480
  },
  disclaimer: {
    mt: 9,
    mb: 6,
    fontWeight: '300',
    fontSize: 0,
    lineHeight: '1.375rem',
    '& a, & a:active': {
      color: 'primary',
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'underline'
    },
    maxWidth: 480
  },
  recaptchaDisclaimer: {
    fontWeight: '300',
    fontSize: 0,
    lineHeight: '1.375rem',
    mb: 14
  },
  startButtonMobile: {
    label: 'startButton',
    display: ['block', 'block', 'block', 'block', 'none', 'none'],
    mt: ['28em', '28em', 200, 200, 1, 1],
    mb: [100, 100, 150, 150, 1, 1],
    mx: ['auto', 'auto', 'auto', 'auto', 0, 0],
    zIndex: 4,
    //iPad
    '@media(min-width: 760px) and (max-width: 770px)': {
      mt: '650px'
    },
    //Pixel 2
    //Pixel 2 XL
    '@media(min-width: 408px) and (max-width: 418px)': {
      mt: '500px'
    },
    //iPhone 6/7/8
    //iPhone X
    '@media(min-width: 374px) and (max-width: 377px) and (max-height: 814px)': {
      mt: '475px'
    },
    //iPhone X landscape
    '@media(orientation: landscape) and (min-width: 374px) and (max-width: 377px), (orientation: landscape) and (max-height: 812px)': {
      mt: '200px'
    },
    //iPhone 5/SE
    '@media(min-width: 318px) and (max-width: 322px)': {
      mt: '360px'
    },
    //Galaxy S5
    '@media(min-width: 358px) and (max-width: 362px)': {
      mt: '390px'
    },
    //Galaxy Fold
    '@media(min-width: 278px) and (max-width: 282px)': {
      mt: '400px'
    },
    '@media(min-width: 425px) and (max-width: 430px)': {
      mt: '580px'
    },
    '@media(orientation: landscape) and (min-width: 560px) and (max-width: 570px)': {
      mt: '9em'
    },
    //Galaxy Fold landscape
    '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
      mt: '8em'
    }
  },

  overlay: {
    width: ['100%', '100%', '100%', '1000%', '100%', '0%'],
    height: ['100%', '100%', '1000%', '100%', '100%', '0%'],
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(1.188rem)'
  },

  surveyToggle: {
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: 2,
    lineHeight: '1.5rem',
    textDecoration: 'none',
    mb: 2,
    mt: [10, 10, 10, 10, 0, 0],
    pt: [10, 10, 10, 10, 0, 0]
  },
  togglePrivacyIcon: {
    display: 'inline-block',
    color: 'primary'
  },
  surveyContainer: {
    transition: 'max-height .5s linear',
    overflow: 'hidden',
    '& label': {
      fontWeight: '300',
      fontSize: 2,
      alignItems: 'flex-start',
      lineHeight: '1.5rem'
    },
    '& textarea': {
      borderRadius: 'button',
      borderColor: 'grey',
      backgroundColor: 'white'
    },
    maxWidth: 440
  },

  startButton: {
    label: 'startButton',
    display: ['block', 'block', 'block', 'block', 'block', 'block'],
    borderRadius: '2.5rem',
    mt: 1,
    mb: '3.125rem',
    mx: 0,
    zIndex: 4,
    fontFamily: 'RocheSans-Light',
    '&:hover': {
      ml: '-0.063rem',
      mt: '-0.063rem'
    }
  },

  textarea: {
    color: 'text',
    fontFamily: 'sans-serif',
    fontSize: 1
  },
  paragraphContainer: {
    transition: 'max-height .5s linear',
    overflow: 'hidden',
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    flexDirection: 'column',
    fontWeight: '300',
    px: 0,
    mt: 2,
    '& p': {
      mb: 3
    },
    maxWidth: 480
  },
  mobileParagraphsContainer: {
    zIndex: 9,
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: 'rgba(0,0,0,0.9)',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '300',
    fontSize: 4,
    px: 7,
    my: 2,
    '& p': {
      my: 3
    }
  },
  privacyPolicyLink: {
    textDecoration: 'underline',
    color: 'currentColor'
  },
  titleContainer: {
    display: 'flex',
    width: '100%'
  },
  mobileContainer: {
    color: '#fff',
    ml: '2rem'
  },

  description: {
    mt: '4.375rem',
    fontSize: '1.063rem',
    fontWeight: '700',
    width: '90%'
  },
  startContainer: {
    display: 'flex',
    alignItems: 'center',
    mt: '2.5rem'
  },
  policy: {
    background: '#fff',
    width: '90%',
    mt: '1.25rem',
    padding: '0.625rem',
    borderRadius: '0.25rem'
  },
  policyItem: {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    mt: '0.625rem'
  },
  footer: {
    position: 'absolute',
    bottom: '1.25rem',
    left: '50%',
    transform: 'translate(-50%, 0)',
    textAlign: 'center',
    fontSize: '0.625rem',
    color: 'black'
  },
  portraitContainer: {
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: '1rem',
    my: '1rem'
  },
  portrait: {
    borderRadius: '50%'
  },
  textContainer2: {
    label: 'text-container',
    position: 'sticky',
    top: ['', '', '', '15%', '15%', '15%'],
    left: ['', '', '', '58%', '65%', '65%'],
    display: 'flex',
    flexDirection: 'column',
    px: '1.25rem',
    height: 'auto',
    maxWidth: '100%',
    color: 'white',
    textAlign: 'left',
    zIndex: 4,
    width: ['auto', 'auto', 'auto', '40%', '33%', '33%'],
    maxHeight: '100%',
    overflowY: ['visible', 'visible', 'visible', 'visible', 'scroll', 'scroll']
  },
  mainCheckboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    mb: '0.5rem'
  },
  checkboxContainer: {
    mr: '0.313rem',
    minWidth: '1.5rem'
  },
  titleText: {
    fontSize: ['1.2rem', '1.4rem'],
    fontWeight: '300',
    my: '0.625rem'
  },
  modaltitleText: {
    fontSize: ['1.2rem', '1.4rem'],
    textAlign: 'center',
    marginBottom: '1.5rem',
    fontWeight: '700'
  },
  subtitleText2: {
    my: '0.625rem',
    fontSize: ['0.9rem', '1rem'],
    fontWeight: '300'
  },
  modalSectionTitle: {
    my: '0.625rem',
    fontSize: ['1rem', '1.2rem'],
    fontWeight: '700'
  },
  clickableText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    textDecorationSkipInk: 'none',
    fontSize: ['0.9rem', '1rem'],
    mx: '0.125rem'
  },
  regularText: {
    color: 'black',
    fontSize: ['0.7rem', '0.8rem'],
    fontFamily: 'RocheSans-Light',
    fontWeight: '100'
  },
  checkboxText: {
    fontSize: ['0.9rem', '1rem']
  },
  buttonText: {
    fontWeight: '550',
    fontSize: ['0.9rem', '1rem'],
    color: 'black',
    textAlign: 'left',
    fontFamily: 'RocheSans-Light'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  modalOverlay: {
    left: 0,
    zIndex: 90,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(1.188rem)',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: '10vh',
    position: 'relative',
    width: 'auto',
    background: 'none',
    minHeight: '70%',
    height: '75vh',
    overflow: 'scroll'
  },
  modalHeader: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1.25rem 1.25rem 0rem 0rem',
    width: '100%',
    backgroundColor: 'white'
  },
  modalFadeTop: {
    position: 'relative',
    height: '2.5rem',
    marginBottom: '-2.5rem',
    width: `calc(100% - 2rem)`,
    bottom: '1px',
    content: '""',
    zIndex: '100',
    display: 'block',
    background:
      'linear-gradient(to bottom, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%)'
  },
  scrollContainer: {
    overflowY: 'scroll',
    mb: '0.5rem',
    '&::-webkit-scrollbar': {
      width: '0.85rem',
      height: '0.313rem',
      opacity: 0.8,
      display: 'block !important'
    },
    '&::-webkit-scrollbar-track': {
      width: '0.25rem',
      pt: '1rem'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CCCCCC',
      borderRadius: '1.5rem'
    }
  },

  languageBox: {
    alignContent: 'right',
    top: ['1rem', '2rem'],
    right: ['1rem', '2rem'],
    position: ['fixed'],
    zIndex: 5
  },
  modalFooter: {
    borderRadius: '0rem 0rem 1.25rem 1.25rem',
    width: '100%',
    backgroundColor: 'white',
    minHeight: '1rem',
    '&::before': {
      position: 'relative',
      height: '2.5rem',
      marginTop: '-2.5rem',
      width: `calc(100% - 2rem)`,
      top: '1px',
      content: '""',
      zIndex: '100',
      display: 'block',
      background:
        'linear-gradient(to bottom, rgba(255,255,255,0) 25%, rgba(255,255,255,1) 80%)'
    }
  },
  modalContent: {
    height: 'auto',
    minHeight: '80%',
    ml: '1px',
    backgroundColor: 'white',
    px: '0.938rem',
    overflow: 'scroll',
    overflowX: 'hidden',
    overflowY: 'auto',
    pt: '1.25rem',
    pb: '2rem',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      display: 'block !important',
      width: '1.875rem',
      borderRadius: '1.25rem 1.25rem 1.25rem 1.25rem'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '1.875rem',
      mt: '1.375rem',
      boxShadow: 'inset 0 0 0.063rem 0.063rem #CCCCCC',
      border: '0.5rem solid rgba(0, 0, 0, 0)'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CCCCCC',
      borderRadius: '1.875rem',
      backgroundClip: 'padding-box',
      border: '0.5rem solid rgba(0, 0, 0, 0)'
      // boxShadow: 'inset 0 0 0.625rem 0.625rem green'
    },
    '& *': {
      msOverflowStyle: '',
      scrollbarWidth: ''
    }
  },
  container: {
    label: 'DigitalHuman',
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(#DFF2F1, 100% ,#577188,100%, #01426A,100%)',
    '& a, & a:active, & a:focus, & button, & button:active, & button:focus': {
      outline: 'none'
    }
  },
  closeContainer: {
    display: 'flex',
    mt: '1rem',
    mb: '0.5rem',
    mr: '2rem',
    justifyContent: 'flex-end',
    '& > svg': {
      fill: 'black',
      height: [20, 20, 20, 24, 24, 24],
      width: [20, 20, 20, 24, 24, 24]
    }
  },
  checkbox: {
    label: 'checkbox',
    width: '1.25rem',
    height: '1.25rem',
    mr: '0.313rem',
    boxShadow: '0rem 0rem 0.188rem 0.0.063rem rgb(255,255,255,0.8)',
    borderRadius: 'button',
    'input:checked ~ &': {
      color: 'text',
      backgroundColor: 'white'
    }
  },
  flagBox: {
    alignContent: 'right',
    top: ['1rem', '2rem'],
    right: ['1rem', '2rem'],
    position: ['fixed'],
    zIndex: 3
  },
  logoContainer: {
    label: 'logo-container',
    position: 'absolute',
    left: '0.938rem',
    top: '1.25rem',
    zIndex: 99,
    display: [
      'none !important',
      'none !important',
      'none !important',
      'block !important',
      'block !important',
      'block !important'
    ]
  }
}
export default styles
