export class Language {
    langCode:string;
    flagCode: string;
    label: string;

    constructor(langCode: string, flagCode: string, label: string) {
        this.langCode = langCode;
        this.flagCode = flagCode;
        this.label = label;
    }

    static parse(i18n:any, languages:string[]){
        const obj = i18n(`languages.EN.label`)
    }
}