import React, { useRef } from 'react'
import { Flex, Image, Text } from 'rebass'
import { useIsSmallScreen, useUneeqState } from 'uneeq-react-core'
import Overlay from '../generic/Overlay'
import PermissionsPrompt, { PermissionsVideo } from '../PermissionsPrompt'
import PermissionsRejected from '../PermissionsRejected'
import AvatarUnavailable from './AvatarUnavailable'
import styles from './styles'
import Config from 'digital-human/src/config'
import { AnimatePresence, motion } from 'framer-motion'

export interface LoadingTip {
  title: string
  videoWebm?: any
  videoMP4?: any
  img?: any
  showOnDesktop?: boolean
  showOnMobile?: boolean
}
interface LoadingProps {
  checkedLoadingTips: Array<LoadingTip>
}

const Loading: React.FC<LoadingProps> = ({ checkedLoadingTips }) => {
  const { loadingPercentage } = useUneeqState()
  const isSmallScreen = useIsSmallScreen()

  const randomDemo: any = useRef(
    checkedLoadingTips[Math.floor(Math.random() * checkedLoadingTips.length)]
  )

  const config = Config.getInstance()

  return (
    <Overlay
      sx={{
        label: 'wrapper',
        width: '100%',
        minHeight: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'right',
        backgroundColor: '#000000',
        backgroundImage: [
          `url('${config.persona.style.home.background.mobile}')`,
          `url('${config.persona.style.home.background.mobile}')`,
          `url('${config.persona.style.home.background.mobile}')`,
          `url('${config.persona.style.home.background.desktop}')`,
          `url('${config.persona.style.home.background.desktop}')`,
          `url('${config.persona.style.home.background.desktop}')`
        ],
        overflow: 'hidden',
        position: 'absolute'
      }}
    >
      <Flex sx={styles.overlay}></Flex>
      <Flex sx={styles.loading.container}>
        <Flex
          sx={styles.loading.videoContainer}
          id="videoContainer"
          data-testid="videoContainer"
        >
          <Text sx={styles.loading.message}>{randomDemo.current.title}</Text>
          {/* <Text sx={styles.loading.message}>{randomDemo.current.title}</Text> */}
          {/* {console.log('teskt lądowania: ', randomDemo.current.title)} */}
          <Flex
            sx={{
              ...styles.loading.barContainer,
              borderColor: config.persona.style.colors.primary['background']
            }}
          >
            <Flex sx={styles.loading.barInnerContainer}>
              <Flex
                sx={{
                  ...styles.loading.bar,
                  backgroundColor:
                    config.persona.style.colors.primary['background'],
                  width: `${loadingPercentage * 5}px`
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Overlay>
  )
}

interface PermissionsGatewayProps {
  restart: () => void
  children: any
  loadingTips: Array<LoadingTip>
  video: PermissionsVideo
}
const PermissionsGateway: React.FC<PermissionsGatewayProps> = ({
  restart,
  children,
  loadingTips,
  video
}) => {
  const { ready, unavailable, permissionAllowed } = useUneeqState()

  return permissionAllowed === null ? (
    <PermissionsPrompt video={video} />
  ) : permissionAllowed === false ? (
    <PermissionsRejected restart={restart} />
  ) : permissionAllowed && ready ? (
    children
  ) : unavailable ? (
    <AvatarUnavailable restart={restart} />
  ) : (
    // UneeQ is not ready or
    // Permissions are completely unknown
    // They could be aproved, rejected or neither
    // Show loader for now..
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Loading checkedLoadingTips={loadingTips} />
      </motion.div>
    </AnimatePresence>
  )
}

export default PermissionsGateway
