import React, { useEffect } from 'react'
import { useSupportedBrowser } from '../hooks'
import { Config } from '../uneeq'

/**
 * Try to get users approval for video/audio early. Doing
 * this ourselves means we know when it happens and we
 * can accurately show the "You need to allow..." message
 */
const usePreApprove = (dispatch: React.Dispatch<any>, config: Config) => {
  const { isGteIOS13, isMobileSafari, isSafari } = useSupportedBrowser()

  useEffect(() => {
    if (config.sendLocalAudio || config.sendLocalVideo) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: isGteIOS13 || isMobileSafari
        })
        .then(mic => dispatch({ type: 'approved', payload: mic }))
        .catch(() => dispatch({ type: 'declined' }))
      // After calling getUserMedia to request permission we wait a while
      // to see if we get a 'quick' response (already allowed of denied)
      // then we can dispatch the 'approving' action.
      setTimeout(() => dispatch({ type: 'approving' }), 1200)
    }
  }, [dispatch, isGteIOS13, isMobileSafari, isSafari])
}

export default usePreApprove
