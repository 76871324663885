import { DigitalHuman, LoadingTip, testState } from 'uneeq-react-ui'
import React, { useState, useRef, useEffect } from 'react'
import { Box } from 'rebass'
import { ThemeProvider } from 'emotion-theming'
import theme from '../theme'
import Config from '../config'
import Home from './Home'
import assets from './assets'
import SessionTimedOut from './SessionTimedOut'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'
const App = (props: any) => {
  const portraitQuery = useMediaQuery({
    query: '(min-width:768px) and (max-width:915px) and (min-height:1000px)'
  })
  const portraitQuery2 = useMediaQuery({
    query: '(min-width:768px) and (orientation: portrait)'
  })
  const config = Config.getInstance()
  const bugherdSource = `https://www.bugherd.com/sidebarv2.js?apikey=${config?.bugherd?.key}`
  const policyCheck = config.persona.features.newPolicy
    ? config.persona.style.home.background.chatBackground.includes('http')
      ? [
          `url('${config.persona.style.home.background.chatBackground}')`,
          `url('${config.persona.style.home.background.chatBackground}')`,
          `url('${config.persona.style.home.background.chatBackground}')`,
          `url('${config.persona.style.home.background.desktop}')`,
          `url('${config.persona.style.home.background.desktop}')`,
          `url('${config.persona.style.home.background.desktop}')`
        ]
      : [
          config.persona.style.home.background.chatBackground,
          config.persona.style.home.background.chatBackground,
          config.persona.style.home.background.chatBackground,
          `url('${config.persona.style.home.background.desktop}')`,
          `url('${config.persona.style.home.background.desktop}')`,
          `url('${config.persona.style.home.background.desktop}')`
        ]
    : [
        `url('${config.persona.style.home.background.mobile}')`,
        `url('${config.persona.style.home.background.mobile}')`,
        `url('${config.persona.style.home.background.mobile}')`,
        `url('${config.persona.style.home.background.desktop}')`,
        `url('${config.persona.style.home.background.desktop}')`,
        `url('${config.persona.style.home.background.desktop}')`
      ]
  const backgroundStyle = {
    label: 'wrapper',
    width: '100%',
    minHeight: '100%',
    backgroundSize: 'cover',
    backgroundPosition: config.persona.name === 'AnaEsmo' ? 'center' : 'right',
    backgroundColor: '#000000',
    backgroundImage: config.persona.style.home.background.portraitBackground
      ? portraitQuery
        ? `url('${config.persona.style.home.background.portraitBackground}')`
        : portraitQuery2
        ? `url('${config.persona.style.home.background.portraitBackgroundAlt}')`
        : policyCheck
      : policyCheck,
    overflow: 'hidden',
    position: 'absolute'
  }

  const { t, i18n } = useTranslation()
  console.log('in18', i18n.language)

  const mediaQuerySmallDevices = useMediaQuery({
    query: '(max-width: 1200px)'
  })
  const mediaQueryMediumDevices = useMediaQuery({
    query: '(min-width: 1201px) and (max-width: 1800px)'
  })
  const mediaQueryLargeDevices = useMediaQuery({
    query: '(min-width: 1800px)'
  })
  const sizes = {
    small: 16,
    medium: 20,
    large: 24
  }

  const currentBreakpoints = () => {
    if (mediaQuerySmallDevices) {
      console.log('SMALL DEVICE')
      return sizes.small
    } else if (mediaQueryMediumDevices) {
      console.log('MEDIUM DEVICE')
      return sizes.medium
    } else if (mediaQueryLargeDevices) {
      console.log('LARGE DEVICE')
      return sizes.large
    }
  }

  useEffect(() => {
    const currentSize = Number(
      document.documentElement.style.getPropertyValue('font-size')
    )
    const size = currentBreakpoints()
    if (currentSize != size) {
      document.documentElement.style.setProperty('font-size', `${size}px`)
    }
  }, [mediaQueryLargeDevices, mediaQuerySmallDevices])

  let loadingTips: LoadingTip[] = t('loading.tips', { returnObjects: true })
  let avatarName
  if (config?.name === 'Diana' && i18n.language === 'fi') {
    avatarName = 'Elina'
  } else {
    avatarName = config?.name
  }
  props.document.title = avatarName + ' - ' + t('document.title')

  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState(testState ? 'digitalHuman' : 'welcome')

  const tokenRef = useRef<string>()
  const start = () => {
    goTo('digitalHuman')
  }

  const restart = () => goTo('welcome')

  return (
    <ThemeProvider theme={theme}>
      {config?.bugherd?.enabled && (
        <Helmet>
          <script type="text/javascript" src={bugherdSource} async></script>
        </Helmet>
      )}

      <Box
        sx={{
          ...backgroundStyle,
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {step === 'digitalHuman' ? (
          <DigitalHuman
            assets={assets}
            config={config}
            token={tokenRef.current}
            loadingTips={loadingTips}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => goTo('restart')}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home startSession={start} />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
