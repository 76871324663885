import React, { useContext } from 'react'
import UneeqContext from '../provider/UneeqContext'
import { Box } from 'rebass'
import { useUneeqState, useIsSmallScreen } from '../hooks'
import { isMobileSafari } from 'react-device-detect'
// @ts-ignore
import useDimensions from 'react-use-dimensions'
import { useMediaQuery } from 'react-responsive'

const UneeqAvatar = ({ ...props }) => {
  const { config } = useContext(UneeqContext)
  const {
    hiddenUI,
    typeModeFromBackend,
    inputMode,
    fullAvatarMode
  } = useUneeqState()
  const { setAvatarVideo } = useContext(UneeqContext)
  const isSmallScreen = useIsSmallScreen()
  const mediaQueryMobileDiana = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const [heightRef, { width, height }] = useDimensions()
  let videoWidth = 0
  let shift = 0
  // if (width && height && isSmallScreen) {
  //   videoWidth = height * config.avatarAspect // full width of the video at this height
  //   const avatarLeft = videoWidth * config.avatarPosition // where the avatar is within video
  //   const center = width / 2 // the center of the frame - where we want the avatar to be
  //   shift = Math.min(0, center - avatarLeft) // How far to shift the video over to center
  // }
  const mediaQueryMobile = window.matchMedia('(max-width: 755px)')
  const mediaQuery = window.matchMedia(
    '(min-width: 566px) and (max-width: 755px)'
  )
  const mediaQueryiPad = window.matchMedia(
    '(min-width: 760px) and (max-width: 770px)'
  )
  const mediaQueryDesktop = window.matchMedia(
    '(min-width: 775px) and (max-width: 2048px)'
  )
  // Check if the media query is true
  if (mediaQuery.matches) {
    if (width && height && isSmallScreen) {
      videoWidth = height * config.avatarAspect // full width of the video at this height
      const avatarLeft = videoWidth * config.avatarPosition // where the avatar is within video
      const center = width / 2 // the center of the frame - where we want the avatar to be
      shift = Math.min(0, center - avatarLeft) // How far to shift the video over to center
    }

    // Then trigger an alert
    // alert('Media Query Matched1!')
  } else if (mediaQueryiPad.matches) {
    if (width && height && isSmallScreen) {
      videoWidth = height * config.avatarAspect // full width of the video at this height
      const avatarLeft = videoWidth * config.avatarPosition // where the avatar is within video
      const center = width / 2 // the center of the frame - where we want the avatar to be
      shift = Math.min(0, center - avatarLeft) // How far to shift the video over to center
    }
    // alert('Media Query Matched2!')
  } else if (mediaQueryDesktop.matches) {
    if (width && height && isSmallScreen) {
      videoWidth = height * config.avatarAspect // full width of the video at this height
      const avatarLeft = videoWidth * 0.6 // where the avatar is within video
      const center = width / 2 // the center of the frame - where we want the avatar to be
      shift = Math.min(0, center - avatarLeft) // How far to shift the video over to center
    }
    // alert('Media Query Matched3!')
  } else {
    if (width && height && isSmallScreen) {
      videoWidth = height * config.avatarAspect // full width of the video at this height
      const avatarLeft = videoWidth * config.avatarPosition // where the avatar is within video
      const center = width / 2 // the center of the frame - where we want the avatar to be
      shift = Math.min(0, center - avatarLeft) // How far to shift the video over to center
    }
  }
  const { transcriptOpen, galleryOpen } = useUneeqState()
  const galleryMode = mediaQueryMobileDiana && transcriptOpen && galleryOpen
  const speechMode = config.sendLocalAudio && inputMode === 'speech'
  const isChatEnabled =
    !speechMode && mediaQueryMobile.matches && transcriptOpen

  const setRefs = (ref: React.ReactElement) => {
    setAvatarVideo(ref)
    heightRef(ref)
  }

  return config.persona.name === 'Diana' ? (
    <Box
      {...props}
      sx={{
        label: 'avatar',
        height: mediaQueryMobileDiana && !fullAvatarMode ? '6.25rem' : '100%',
        width: mediaQueryMobileDiana && !fullAvatarMode ? '6.25rem' : '100%',
        borderRadius: mediaQueryMobileDiana && !fullAvatarMode ? '50%' : 0,
        borderBottomLeftRadius:
          mediaQueryMobileDiana && !fullAvatarMode ? '50%' : 0,
        borderBottomRightRadius:
          mediaQueryMobileDiana && !fullAvatarMode ? '50%' : 0,
        borderTopLeftRadius:
          mediaQueryMobileDiana && !fullAvatarMode ? '50%' : 0,
        borderTopRightRadius:
          mediaQueryMobileDiana && !fullAvatarMode ? '50%' : 0,
        WebkitBorderRadius:
          mediaQueryMobileDiana && !fullAvatarMode ? '50%' : 0,
        MozBorderRadius: mediaQueryMobileDiana && !fullAvatarMode ? '50%' : 0,
        marginTop: mediaQueryMobileDiana && !fullAvatarMode ? '0.625rem' : 0,
        marginLeft: mediaQueryMobileDiana && !fullAvatarMode ? '0.625rem' : 0,
        zIndex: '10000',
        overflow: mediaQueryMobileDiana && !fullAvatarMode ? 'hidden' : 'auto',
        '& video': {
          minWidth: videoWidth,
          borderRadius: mediaQueryMobileDiana && !fullAvatarMode ? '50%' : '0',
          marginLeft: shift,
          zIndex: '100',
          mt: hiddenUI
            ? isMobileSafari
              ? typeModeFromBackend
                ? 0
                : width < 768
                ? ['50%', '50%', '50%', '50%', 0, 0]
                : ['30%', '30%', '30%', '30%', 0, 0]
              : 0
            : {}
        }
      }}
      ref={setRefs}
    />
  ) : (
    <Box
      {...props}
      sx={{
        label: 'avatar',
        height: isChatEnabled ? '5rem' : '100%',
        width: isChatEnabled ? '5rem' : '100%',
        borderRadius: isChatEnabled ? '50%' : 0,
        borderBottomLeftRadius: isChatEnabled ? '50%' : 0,
        borderBottomRightRadius: isChatEnabled ? '50%' : 0,
        borderTopLeftRadius: isChatEnabled ? '50%' : 0,
        borderTopRightRadius: isChatEnabled ? '50%' : 0,
        WebkitBorderRadius: isChatEnabled ? '50%' : 0,
        MozBorderRadius: isChatEnabled ? '50%' : 0,
        marginTop: isChatEnabled ? '0.625rem' : 0,
        marginLeft: isChatEnabled ? 'calc(16.5% - 2.5rem)' : 0,
        zIndex: '10000',
        overflow: isChatEnabled ? 'hidden' : 'auto',
        '& video': {
          minWidth: videoWidth,
          marginLeft: shift,
          borderRadius: isChatEnabled ? '50%' : '0',
          zIndex: '100',
          mt: hiddenUI
            ? isMobileSafari
              ? typeModeFromBackend
                ? 0
                : width < 768
                ? ['50%', '50%', '50%', '50%', 0, 0]
                : ['30%', '30%', '30%', '30%', 0, 0]
              : 0
            : {}
        }
      }}
      ref={setRefs}
    />
  )
}

export default UneeqAvatar
