const colors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',

  primary: '#DC7D58',
  primaryLight: '#DC7D58',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryLight: '#4bd6c4',
  secondaryText: '#FFFFFF',

  tertiary: '#87ceeb',
  tertiaryLight: '#4cc5f5',
  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  error: '#BF2828',
  warning: '#FBBD1D',
  
  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#1C8728',
  timeoutTrailColor: '#BDC0C7'
}

const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

export const theme = {
  colors: {
    ...colors,

    // PTT animation
    pttMobileGlow: hex2rgba(colors.tertiary, 0.2), // Mobile - glow around button while recording

    // PTT desktop colors & gradients
    pttColRecording: 'white',
    pttImgRecording: `radial-gradient(circle, ${colors.tertiary} 0%, white 100%)`,
    pttColSending: 'tertiary',
    pttImgSending: `radial-gradient(circle, white 0%,${colors.tertiary} 100%)`,
    pttColWaiting: 'tertiary',
    pttImgWaiting: 'tertiary'
  },
  fonts: {
    body: 'Overpass, Roboto, system-ui, sans-serif',
    heading: 'inherit'
  },
  fontSizes: ['0.875rem', '0.938rem', '1rem', '1.25rem', '1.375rem', '1.5rem', '1.875rem'],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: '1.938rem'
  },
  space: [
    0,
    4,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    28,
    32,
    40,
    48,
    56,
    64,
    80,
    100,
    128
  ],
  radii: {
    button: 8,
    card: 8
  },
  shadows: {
    card: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)'
  },
  text: {
    heading: {
      fontFamily: 'RocheSans',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 5,
      pb: 2,
      mt: 3,
      mx: 4
    }
  },
  variants: {
    link: {
      color: 'primary'
    },
    card: {
      bg: 'white',
      color: 'textLight',
      borderRadius: 'card',
      p: [6, 6, 12, 12, 12, 12],
      boxShadow: 'card'
    },
    modal: {
      variant: 'variants.card',
      label: 'modal',
      width: ['92%', '92%', 850],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    transcript: {
      borderRadius: '0.188rem',
      overflow: 'hidden',
      top: 0,
      right: 0,
      width: ['100%', '100%', '100%', '100%', 238, 238],
      height: ['100%', '100%', '100%', '100%', 504, 504],
      zIndex: 3,
      color: 'text',
      backgroundColor: 'greyLight',
      '& .transcript-header': {
        height: 62,
        position: ['fixed', 'fixed', 'fixed', 'fixed', 'static'],
        backgroundColor: 'primary',
        color: 'text',
        top: [0, 0, 0, 0, 'auto', 'auto'],
        zIndex: 10
      },
      '& .transcript-content': {
        fontSize: [4, 1],
        p: 3,
        height: ['100%', '100%', '100%', '100%', 'auto', 'auto'],
        paddingTop: [62, 62, 62, 62, 3, 3],
        paddingBottom: [53, 53, 53, 53, 3, 3]
      }
    }
  },
  buttons: {
    cursor: 'pointer',
    // TODO
    unstyled: {
      background: 'transparent',
      color: 'secondary',
      fontFamily: 'inherit',
      fontSize: 0,
      cursor: 'pointer'
    },
  },
  breakpoints: ['320px', '600px', '769px', '1024px', '1280px']
}

export default theme
