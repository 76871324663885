import React, { useState, useContext, useEffect } from 'react'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import { Flex, Text, Button } from 'rebass'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

const TextSizeChanger = () => {
  const mediaQuerySmallDevices = useMediaQuery({
    query: '(max-width: 1200px)'
  })
  const mediaQueryMediumDevices = useMediaQuery({
    query: '(max-width: 1800px)'
  })
  const mediaQueryLargeDevices = useMediaQuery({
    query: '(min-width: 1800px)'
  })
  const { screenZoom } = useUneeqState()

  const { dispatch, config } = useContext(UneeqContext)

  const { t } = useTranslation()

  const small = {
    80: 12,
    //85:13,
    90: 14,
    //95:15,
    100: 16,
    //105:17,
    110: 18,
    //115:19,
    120: 20
  }

  const medium = {
    80: 16,
    //85:17,
    90: 18,
    //95:19,
    100: 20,
    //105:21,
    110: 22,
    //115:23,
    120: 24
  }

  const large = {
    80: 19,
    //85:20,
    90: 21,
    //95:22,
    100: 24,
    //105:25,
    110: 26,
    //115:27,
    120: 28
  }

  const currentBreakpoints = () => {
    if (mediaQuerySmallDevices) {
      console.info('mediaQuerySmallDevices')
      return small
    } else if (mediaQueryLargeDevices) {
      console.info('mediaQueryLargeDevices')
      return large
    } else {
      console.info('mediaQueryMediumDevices')
      return medium
    }
  }

  const changeSizeByIndex = newIndex => {
    const currentBreakpoint = currentBreakpoints()

    if (newIndex === -1)
      newIndex = (Object.keys(currentBreakpoint).length - 1) / 2

    // console.info(
    //   `new: ${Object.keys(currentBreakpoint)[newIndex]} index: ${newIndex}`
    // )

    const size = {
      percentage: Object.keys(currentBreakpoint)[newIndex],
      value: Object.values(currentBreakpoint)[newIndex]
    }

    dispatch({ type: 'changeRemSize', payload: size.value })
    dispatch({ type: 'changeScreenZoom', payload: size.percentage })
    document.documentElement.style.setProperty('font-size', `${size.value}px`)
    return size
  }

  const changeSizeByIndexWithModifier = indexModifier => {
    //Get the current index and add or reduce, based on the IndexModifier
    const currentBreakpoint = currentBreakpoints()
    const currentIndex = Object.keys(currentBreakpoint).indexOf(screenZoom)
    console.info(`current: ${screenZoom} index: ${currentIndex}`)

    const newIndex = currentIndex + indexModifier
    if (newIndex < 0 || newIndex > Object.keys(currentBreakpoint).length - 1)
      return
    changeSizeByIndex(newIndex)
  }

  const decreaseSize = () => changeSizeByIndexWithModifier(-1)
  const increaseSize = () => changeSizeByIndexWithModifier(+1)

  const refreshSize = () => {
    const currentIndex = Object.keys(currentBreakpoints()).indexOf(screenZoom)
    changeSizeByIndex(currentIndex)
  }

  useEffect(() => {
    refreshSize()
  }, [window.innerWidth])

  const reset = () => {
    changeSizeByIndex(Object.keys(currentBreakpoints()).indexOf('100'))
  }

  const menuButtonColor = config.persona.style.colors.buttons.settings
    ? config.persona.style.colors.buttons.settings
    : config.persona.style.colors.buttons.basic

  return (
    <Flex sx={styles.textSizeChanger}>
      <Flex sx={styles.textSizeChangerControllersWrapper}>
        <Flex
          onClick={decreaseSize}
          sx={{
            ...styles.textSizeChangerControllers,
            ...menuButtonColor
          }}
        >
          <Text sx={styles.textSizeChangerController}>-</Text>
        </Flex>
        <Text style={{ margin: '0 0.5rem 0', fontSize: '1rem' }}>
          {screenZoom}%
        </Text>
        <Flex
          onClick={increaseSize}
          sx={{
            ...styles.textSizeChangerControllers,
            ...menuButtonColor
          }}
        >
          <Text sx={styles.textSizeChangerController}>+</Text>
        </Flex>
      </Flex>
      <Flex>
        <Button onClick={reset} sx={{ ...menuButtonColor, fontSize: '1rem' }}>
          {t('Menu.reset')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default TextSizeChanger
