import React, { useContext } from 'react'
import { styles as s } from './styles'
import { useUneeqState } from 'uneeq-react-core'
import { useMediaQuery } from 'react-responsive'

interface EmailAction {
  type: 'email'
  to: string
  subject: string
  body: string
}

interface PhoneAction {
  type: 'phone'
  number: string
}

interface OpenLinkAction {
  type: 'openLink'
  link: string
}

export type ActionItem = EmailAction | PhoneAction | OpenLinkAction

interface ActionProps {
  action: ActionItem
}

const styles = s.action

const Action: React.FC<ActionProps> = ({ action }) => {
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 755px)'
  })
  switch (action.type) {
    case 'email':
      let params = action.subject || action.body ? '?' : ''
      if (action.subject)
        params += `subject=${encodeURIComponent(action.subject)}`
      if (action.body)
        params += `${action.subject ? '&' : ''}body=${encodeURIComponent(
          action.body
        )}`
      window.open(`mailto:${action.to}${params}`)
      break
    case 'phone':
      if (mediaQueryMobile) window.location.href = `tel:${action.number}`
      break
    case 'openLink':
      window.open(action.link)
      break
  }

  const { onScreenInfo } = useUneeqState()
  onScreenInfo.action = null

  return <></>
}

export default Action
