import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  useMemo
} from 'react'
import { Uneeq } from 'uneeq-js'
import { setEventHandler, trackUneeqMessage } from '../analytics'
import { useSupportedBrowser } from '../hooks'
import defaultConfig from './defaultConfig'
import reducer from './state/reducer'
import initialState, {
  closeDialogs,
  UneeqCoreConfig
} from './state/initialState'
import UneeqContext from './UneeqContext'
import usePreApprove from './usePreApprove'
import useTimeoutUpdate from './useTimeoutUpdate'
import useSpacebarToTalk from './useSpacebarToTalk'
import { AnyUneeqMessage, UneeqState, Config } from '../uneeq'
import { useIdleTimer } from 'react-idle-timer'
import i18n from 'i18next'

import RecordRTC, { StereoAudioRecorder } from 'recordrtc'

interface UneeqProviderProps {
  children: React.ReactNode
  onSessionEnded: () => void
  onTimedOut: () => void
  postInit?: (uneeq: Uneeq) => void
  config: Partial<UneeqCoreConfig>
  token?: string
  sendRevokation?: Function
}
const UneeqProvider: React.FC<UneeqProviderProps> = ({
  children,
  onSessionEnded,
  postInit,
  onTimedOut,
  config,
  token
}) => {
  const uneeq: any = useRef() // UneeQ Instance
  const { isGteIOS13, isMobileSafari } = useSupportedBrowser()

  const loggingSession = (dHId: number, sessionID: string) => {
    dispatch({ type: 'setSessionID', payload: sessionID })
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }
    // `http://localhost:8080/api/logSession/c235e7b2e50e29d549561231ecbbe28ec3fa6b61/${dHId}/${sessionID}/${new Date().getTime()}`,
    // `https://live-monitoring-consent-logging-dot-ecp-0156.ew.r.appspot.com/api/logSession/c235e7b2e50e29d549561231ecbbe28ec3fa6b61/${dHId}/${sessionID}/${new Date().getTime()}`,
    fetch(
      `https://live-monitoring-consent-logging-dot-ecp-0156.ew.r.appspot.com/api/logSession/c235e7b2e50e29d549561231ecbbe28ec3fa6b61/${dHId}/${sessionID}/${new Date().toString()}`,
      options
    ).catch(error => {
      console.log(`SESSSION ID='${sessionID}' NOT SAVED`)
      console.error(error.message)
    })

    console.log(`SESSSION ID='${sessionID}' SAVED`)
  }
  const finalConfig = useMemo(
    () =>
      ({
        ...defaultConfig,
        ...config,
        // in some cases we have to set sendLocalVideo to true
        sendLocalVideo:
          config.sendLocalVideo ||
          (config.sendLocalAudio && isMobileSafari && isGteIOS13),
        sendLocalAudio: config.sendLocalAudio
      } as Config),
    [config, isMobileSafari, isGteIOS13]
  )

  if (!config.persona) throw new Error(`Persona Profile not defined`)

  if (!config.nlp?.dialogflow && !config.nlp?.statworks)
    throw new Error(
      `Dialogflow and gpt3 not defined, at least one you should define.`
    )

  let statworks3Param = ''
  let dialogflowParam = ''
  let environmentParam = ''

  if (config.nlp.dialogflow) {
    const urlParams = new URLSearchParams(window.location.search)
    const dialogflowAgentId = config.nlp.dialogflow?.agent

    const defaultEnvironment =
      config.nlp.dialogflow?.environments.find(e => e.isDefault) ||
      config.nlp.dialogflow?.environments[0]
    if (!urlParams.get('env') && !defaultEnvironment)
      throw new Error('There is not Environment defined ')

    dialogflowParam = dialogflowAgentId
      ? `&dialogflowId=${dialogflowAgentId}`
      : ''

    environmentParam = urlParams.get('env')
      ? `&environment=${urlParams.get('env')}`
      : `&environment=${defaultEnvironment?.id}`
  } else {
    statworks3Param = `&statworks=${config.nlp.statworks}`
  }

  const personaIdParam = config.persona.uneeq?.id
    ? `&personaId=${config.persona.uneeq.id}`
    : ''
  const languageParam = i18n.language ? `&language=${i18n.language}` : ''

  finalConfig.server = `${config.server}?action=token${personaIdParam}${statworks3Param}${dialogflowParam}${languageParam}${environmentParam}`

  // Elements
  const [avatarVideo, setAvatarVideo] = useState<HTMLDivElement>()
  const [localVideo, setLocalVideo] = useState<HTMLDivElement>()

  const reducerWithConfig = useCallback(
    (state: UneeqState, action: any) => {
      if (action.type === 'uneeqMessage') {
        // console.log("uneeq message type ",message.uneeqMessageType)
        // a message from the UneeQ backend
        const message = action.payload
        if (message.uneeqMessageType != 'WebRtcData')
          console.info('Action - uneeqMessage ' + message.uneeqMessageType)

        if (message.uneeqMessageType === 'SessionError') console.info(message)

        if (message.uneeqMessageType === 'SessionLive') sendEvent('welcome')
        if (message.uneeqMessageType === 'FinishedSpeaking') {
          console.info('=== STOP SPEAKING ===')
          if (
            state.onScreenInfo?.information &&
            state.onScreenInfo?.information.hasOwnProperty('event')
          ) {
            let customEvent = state.onScreenInfo.information
              ? state.onScreenInfo.information
              : ''
            console.info('Event triggered: ' + JSON.stringify(customEvent))

            sendEvent(customEvent['event'])
          }
        }
      } else {
        console.info('Action -', action.type)
      }

      const newState = reducer(state, action, finalConfig)

      return newState
    },
    [finalConfig]
  )
  const [state, dispatch] = useReducer(
    reducerWithConfig,
    initialState(finalConfig)
  )
  // console.log("STATE", state)
  //   if(state.avatarSpeaking === true){
  //     console.log("TRUE", state.currentMessage)
  //     console.log(state.currentMessage.length)
  //     //state.currentMessage = state.currentMessage.replace(" ⠀", "")
  //     if(state.currentMessage == " ⠀"){
  //     // {dispatch({
  //     //   type: 'uneeqMessage',
  //     //   payload: { uneeqMessageType: 'FinishedSpeking' }
  //     // })}

  //   }
  // }

  const handleUneeqMessage = (message: AnyUneeqMessage) => {
    dispatch({ type: 'uneeqMessage', payload: message })
    trackUneeqMessage(message)
  }

  // Manage permissions approval process (unless using a testState)
  usePreApprove(dispatch, finalConfig)

  useEffect(() => {
    if (finalConfig.analytics) {
      setEventHandler(finalConfig.analytics)
    }
  }, [finalConfig.analytics])

  // put handleUneeqMessage into a ref so we can turn it into a noop prevent UneeQ-js from calling it after unmount
  const messageHandler = useRef(handleUneeqMessage)

  useEffect(() => {
    if (avatarVideo) {
      uneeq.current = new Uneeq({
        url: finalConfig.persona.uneeq?.server || '',
        conversationId: finalConfig.persona.uneeq?.id || '',
        messageHandler: (msg: any) => messageHandler.current(msg),
        avatarVideoContainerElement: avatarVideo as HTMLDivElement,
        localVideoContainerElement: localVideo as HTMLDivElement,
        sendLocalAudio: false,
        sendLocalVideo: false,
        customData: finalConfig.customData,
        voiceInputMode: 'PUSH_TO_TALK'
      })

      // Fetch token
      fetch(finalConfig.server)
        .then(response => {
          if (response.status === 200) {
            return response.json()
          } else {
            return Promise.reject(new Error(response.statusText))
          }
        })
        .then(response => {
          const { token } = response
          uneeq.current.initWithToken(token).then(r => {
            finalConfig.customData = response['fm-custom-data']
            loggingSession(finalConfig.id, uneeq.current.session.id)
            console.info('Body settled to the config')
          })
        })
        .catch(error => {
          dispatch({ type: 'tokenError', message: JSON.stringify(error) })
        })

      // Return cleanup
      return () => {
        // make the handler a noop so dispatch is not called after unmount
        messageHandler.current = () => {}
      }
    }
  }, [avatarVideo])

  const endSession = () => {
    uneeq.current.endSession()
  }

  const { sessionEnded, timedOut } = state

  useEffect(() => {
    if (timedOut) onTimedOut()
    if (sessionEnded) onSessionEnded()
  }, [onTimedOut, timedOut, onSessionEnded, sessionEnded])

  const sendText = (text: string) => {
    state.awaitingResponse = true
    dispatch({
      type: 'uneeqMessage',
      payload: { uneeqMessageType: 'AvatarQuestionText' }
    })
    sendData(text, 'QUESTION')
  }

  const sendEvent = async (event: string) => {
    finalConfig.customData['event'] = event.toLowerCase()
    state.awaitingResponse = true
    await sendData('', 'EVENT')
    if (event === 'CLOSE_SESSION') {
      endSession()
    }
  }

  const sendData = async (data: any, type: string) => {
    let sessionID = uneeq.current.sessionId
    const json = {
      sid: sessionID,
      'fm-custom-data': finalConfig.customData,
      'fm-avatar': { type: type, avatarSessionId: sessionID },
      'fm-question': data
    }

    const response = await fetch(`${config.server}?action=query`, {
      method: 'POST',
      body: JSON.stringify(json),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })

    console.groupCollapsed('SEND DATA')
    console.info('BODY')
    console.info(JSON.stringify(json))
    console.info('Response')
    console.info(JSON.stringify(response))
    console.groupEnd()

    const [userMessage, botMessage] = await response.json()

    dispatch({
      type: 'serverResponse',
      payload: {
        userMessage: userMessage,
        botMessage: botMessage
      }
    })

    // if (botMessage.answer == "<speak xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='http://www.w3.org/2001/mstts' xmlns:emo='http://www.w3.org/2009/10/emotionml' version='1.0' xml:lang='de-DE'><voice name='de-DE-KatjaNeural'><prosody rate='-5.00%'> <p>⠀</p></prosody></voice></speak>") {
    //   console.log("<p>")
    //   console.log("przed dispatch")
    //   // dispatch({
    //   //   type: 'uneeqMessage',
    //   //   payload: { uneeqMessageType: 'FinishedSpeaking' }
    //   // })
    //    console.log("po dispatch")
    //   // dispatch(reducerWithConfig)
    //   // console.log("po dispatchm 2 ")

    // }
  }

  const startRecording = () => {
    if (state.recording || state.avatarSpeaking || state.sending) {
      console.info('Not start record because there is a event happening:')
      console.info(
        `state: ${state.recording} speaking: ${state.avatarSpeaking} sending: ${state.sending}`
      )
      return false
    }

    if (uneeq.current.recordAudio) {
      uneeq.current.recordAudio.destroy()
      uneeq.current.recordAudio = null
    }

    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: isGteIOS13 || isMobileSafari
      })
      .then(mic => {
        uneeq.current.recordAudio = new RecordRTC(mic.clone(), {
          type: 'audio',
          mimeType: 'audio/webm',
          recorderType: StereoAudioRecorder,
          numberOfAudioChannels: 1
        })
        uneeq.current.recordAudio.startRecording()
        dispatch({
          type: 'uneeqMessage',
          payload: {
            uneeqMessageType: 'RecordingStarted'
          }
        })
      })
  }

  const stopRecording = async () => {
    dispatch({
      type: 'uneeqMessage',
      payload: { uneeqMessageType: 'RecordingStopped' }
    })
    try {
      uneeq.current.recordAudio.stopRecording(() => {
        uneeq.current.recordAudio.getDataURL(data => {
          console.info(data)
          const dataObject = {
            audio: {
              type: 'audio/wav',
              dataURL: data
            }
          }
          sendData(dataObject, 'AUDIO')
        })
      })
    } catch (e) {
      console.info(e)
    }
  }
  if (!config.persona.features?.buttonOnly) {
    useSpacebarToTalk(state, {
      start: startRecording,
      stop: stopRecording
    })
  }

  // dispatch `timeoutUpdate` actions as needed to keep the state updated
  const { resetTimeout } = useTimeoutUpdate(
    state,
    dispatch,
    onTimedOut,
    finalConfig.timeoutWarning
  )

  // @ts-ignore
  const setDevice = useCallback((deviceType, deviceId) => {
    localStorage.setItem(deviceType, deviceId)
    switch (deviceType) {
      case 'videoInput':
        uneeq.current.setCamera(deviceId)
        break
      case 'audioInput':
        uneeq.current.setMic(deviceId)
        break
      case 'audioOutput':
        uneeq.current.setSpeaker(deviceId)
        break
      default:
        console.error('unrecognised device type:', deviceType)
        break
    }
  }, [])

  const volume = {
    watch: (listener: any) => {
      const setter = () =>
        listener((avatarVideo?.children[0] as HTMLVideoElement)?.volume)
      avatarVideo?.children[0].addEventListener('volumechange', setter)
      // call now to set initial value
      setter()

      // cleanup function
      return () =>
        avatarVideo?.children[0].removeEventListener('volumechange', setter)
    },
    set: (level: number) => {
      if (avatarVideo) {
        ;(avatarVideo.children[0] as HTMLVideoElement).volume = level
      }
    }
  }

  const allDialogsClosed = () => {
    return Object.keys(closeDialogs).every(
      key =>
        state[key as keyof typeof closeDialogs] ===
        closeDialogs[key as keyof typeof closeDialogs]
    )
  }

  const context = {
    dispatch,
    setAvatarVideo,
    avatarVideo,
    setLocalVideo,
    localVideo,
    sendText,
    sendData,
    sendEvent,
    setDevice,
    endSession,
    resetTimeout,
    volume,
    startRecording,
    stopRecording,
    config: finalConfig,
    state,
    sessionId: uneeq.current?.sessionId,
    allDialogsClosed,
    hideModal: () => dispatch({ type: 'closeModal' }),
    testMessage: (message: string) =>
      dispatch({ type: 'uneeqMessage', payload: message })
  }

  const [timeoutCount, setTimeoutCount] = useState(1)

  const triggerAction = () => {
    document.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'e',
        code: 'KeyE',
        shiftKey: false,
        ctrlKey: false,
        metaKey: false
      })
    )
  }

  const onAction = () => {
    const events = [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ]

    for (let event of events) {
      document.addEventListener(event, (e: any) => {
        if (e.code !== 'KeyE') {
          setTimeoutCount(1)
        }
      })
    }
  }

  const onActive = () => {
    // console.log('user is doing something')
  }

  const onIdle = () => {
    setTimeoutCount(timeoutCount + 1)
    console.log('user is idle: ', timeoutCount)

    if (timeoutCount === 1) {
      sendEvent('Timeout_1')
      triggerAction()
    } else if (timeoutCount === 2) {
      sendEvent('Timeout_2')
      triggerAction()
    } else if (timeoutCount === 3) {
      sendEvent('Timeout_3')
      setTimeout(() => {
        endSession()
      }, 20000)
      triggerAction()
    } else if (timeoutCount > 3) {
      setTimeoutCount(1)
    }
  }

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle,
    onAction,
    onActive,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange'
    ]
  })

  return (
    <UneeqContext.Provider value={context}>{children}</UneeqContext.Provider>
  )
}

export default UneeqProvider
