import React, { useContext } from 'react'
import { useUneeqState, UneeqContext } from 'uneeq-react-core'
import InformationContent from './InformationContent'
import { InformationItem } from './InformationTypes'
import Transliteration from './Transliteration'
import Config from 'digital-human/src/config'
import { useMediaQuery } from 'react-responsive'
import { Box } from 'rebass'

interface InformationProps {
  information: InformationItem[]
}
const Information: React.FC<InformationProps> = ({ information }) => {
  const config = Config.getInstance()
  const { onScreenInfo, fullAvatarMode } = useUneeqState()
  const { state } = useContext(UneeqContext)
  return (
    <Box
      sx={{
        padding: '1.25rem'
      }}
    >
      {config.persona.name === 'Diana' && !fullAvatarMode ? (
        <Transliteration information={information} />
      ) : (
        (!state.avatarSpeaking || state.mediaActive) && (
          <InformationContent information={information} />
        )
      )}
    </Box>
  )
}

export default Information
