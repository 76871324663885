import React, { useContext } from 'react'
import { Box, Flex } from 'rebass'
import styles from './styles'
import UserQuestion from './UserQuestion'
import { motion, AnimatePresence } from 'framer-motion'
const MotionUserQuestion = motion.custom(UserQuestion)
import Config from 'digital-human/src/config'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'

const Captions = () => {
  const config = Config.getInstance()
  let {
    captionVisible,
    transcriptOpen,
    galleryOpen,
    videoFullscreen,
    currentMessage,
    menuOpen
  } = useUneeqState()
  const { state } = useContext(UneeqContext)
  let smallSplit = currentMessage && currentMessage.includes(':::')
  let splitCondition = smallSplit ? ':::' : '::'
  return (
    <Box
      sx={{
        ...styles.captionsContainer,
        bottom: videoFullscreen ? '2rem' : '6rem'
      }}
    >
      {config.persona.name !== 'Diana' ? (
        <Flex
          sx={
            config.persona.limitTextContainerHeight
              ? { ...styles.userQuestionMotionContainer, overflow: 'auto' }
              : styles.userQuestionMotionContainer
          }
        >
          <AnimatePresence>
            {captionVisible &&
              !transcriptOpen &&
              !menuOpen &&
              currentMessage.length && (
                <MotionUserQuestion
                  variants={{
                    start: {
                      opacity: 0,
                      transition: {
                        delay: 1
                      },
                      transform: 'translateX(-200px)'
                    },
                    end: {
                      opacity: 1,
                      transition: {
                        delay: 1
                      },
                      transform: 'translateX(1px)'
                    },
                    final: {
                      opacity: 0,
                      transform: 'translateX(200px)'
                    }
                  }}
                  initial="start"
                  animate="end"
                  exit="final"
                  key="question"
                >
                  <div style={{ textAlign: 'center' }}>
                    {state.avatarSpeaking &&
                      currentMessage
                        .split(splitCondition)
                        .map((line: string, i: number) => {
                          const bigText =
                            line.length > 500 &&
                            config.persona.limitTextContainerHeight
                          return (
                            <div
                              style={
                                bigText
                                  ? {
                                      background: 'transparent',
                                      padding: '2px 20px 2px 10px',
                                      borderBottomRightRadius: '50px',
                                      color: 'white',
                                      fontSize: '18px',
                                      lineHeight: '20px'
                                    }
                                  : {
                                      background: 'transparent',
                                      padding: '10px 20px 10px 10px',
                                      borderBottomRightRadius: '50px',
                                      color: 'white'
                                    }
                              }
                              key={i}
                            >
                              {line}
                            </div>
                          )
                        })}
                  </div>
                </MotionUserQuestion>
              )}
          </AnimatePresence>
        </Flex>
      ) : (
        <Flex sx={styles.userQuestionMotionContainer}>
          <AnimatePresence>
            {!galleryOpen &&
              captionVisible &&
              !transcriptOpen &&
              !menuOpen &&
              currentMessage.length && (
                <MotionUserQuestion
                  variants={{
                    start: {
                      opacity: 0,
                      transition: {
                        delay: 1.5
                      },
                      transform: 'translateX(-200px)'
                    },
                    end: {
                      opacity: 1,
                      transition: {
                        delay: 1.5
                      },
                      transform: 'translateX(0.063rem)'
                    },
                    final: {
                      opacity: 1,
                      transform: 'translateX(200px)'
                    }
                  }}
                  initial="start"
                  animate="end"
                  exit="final"
                  key="question"
                >
                  <div style={{ textAlign: 'center' }}>
                    {state.avatarSpeaking &&
                      currentMessage
                        .split('::')
                        .map((line: string, i: number) => {
                          return (
                            <div
                              style={{
                                background: 'transparent',
                                padding: '0.625rem 1.25rem 0.625rem 0.625rem',
                                borderBottomRightRadius: '3.125rem',
                                color: 'white'
                              }}
                              key={i}
                            >
                              {line}
                            </div>
                          )
                        })}
                  </div>
                </MotionUserQuestion>
              )}
          </AnimatePresence>
        </Flex>
      )}
    </Box>
  )
}

export default Captions
