import React, {
  useRef,
  useState,
  useContext,
  useLayoutEffect,
  useEffect
} from 'react'
import { Flex, Box } from 'rebass'
import { ReactComponent as ExpandIcon } from '../../assets/img/expand.svg'
import { ReactComponent as CompressIcon } from '../../assets/img/reset.svg'
import { ReactComponent as PlayIcon } from '../../assets/img/play-button-svgrepo-com.svg'
import { ReactComponent as PlayIconIdle } from '../../assets/img/play-button-transcript.svg'
import { ReactComponent as PauseIcon } from '../../assets/img/pause-svgrepo-com.svg'
import { useMediaQuery } from 'react-responsive'
import { styles } from './styles'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
interface PlayerProps {
  fullscreen?: boolean
  index: number
}
const Player: React.FC<PlayerProps> = ({ fullscreen, index }) => {
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width:768px)'
  })
  const videoRef = useRef<HTMLVideoElement>(null)
  const progressRef = useRef<HTMLProgressElement>(null)
  const [progress, setProgress] = useState(0)
  const [mode, setMode] = useState(fullscreen ? true : false)
  const [ready, setReady] = useState(false)
  const { dispatch, sendEvent } = useContext(UneeqContext)
  const {
    transcriptOpen,
    videoOpen,
    videoGallery,
    transcriptHasOpened,
    menuOpen
  } = useUneeqState()
  const [isPlaying, setIsPlaying] = useState(mode || !transcriptOpen)
  const current = videoGallery.get(index)
  const source = current.videoSource
  const played = current?.played
  useLayoutEffect(() => {
    const handleVideoEnd = () => {
      if (!transcriptOpen) send('GO_NEXT')
      else setIsPlaying(false)
    }
    if (videoRef.current) {
      videoRef.current.addEventListener('ended', handleVideoEnd)
    }
    return () => videoRef?.current?.removeEventListener('ended', handleVideoEnd)
  }, [videoRef.current])

  useEffect(() => {
    if (videoRef.current && current) {
      if ((transcriptOpen && played) || !transcriptOpen)
        videoRef.current.currentTime = current.videoTime
    }
  }, [videoRef?.current])

  useEffect(() => {
    if (videoRef?.current && played && transcriptHasOpened) {
      videoRef.current.currentTime = current.videoTime
      videoRef.current.play()
      setIsPlaying(true)
    }
    return () => {
      if (transcriptOpen) {
        dispatch({
          type: 'updateTime',
          time: videoRef?.current?.currentTime,
          index: index
        })
      }
    }
  }, [videoOpen])
  useEffect(() => {
    if (videoRef?.current) {
      if (menuOpen) videoRef.current.pause()
    }
  }, [menuOpen])
  const handleProgress = () => {
    if (videoRef?.current) {
      const duration = videoRef.current?.duration
      const currentTime = videoRef.current?.currentTime
      const progress = (currentTime / duration) * 100
      setProgress(progress)
    }
  }
  const togglePlay = () => {
    if (isPlaying) {
      videoRef?.current?.pause()
    } else {
      videoRef?.current?.play()
    }
    setIsPlaying(!isPlaying)
  }
  const toggleFullScreen = () => {
    if (videoOpen) {
      dispatch({
        type: 'closePlayer',
        time: videoRef?.current?.currentTime,
        index: index
      })
    } else {
      setMode(mode => !mode)
      dispatch({ type: 'videoFullscreen' })
    }
  }

  const toggleFullScreenTranscript = () => {
    if (videoRef.current) {
      const time = videoRef?.current?.currentTime
      if (isPlaying) setIsPlaying(false)
      dispatch({ type: 'openPlayer', index: index, time: time })
    }
  }
  const handleClick = e => {
    if (videoRef?.current) {
      let rect = e.currentTarget.getBoundingClientRect()
      let clickX = e.clientX
      let boundLeft = Math.floor(rect?.left)
      let boundRight = Math.floor(rect?.right)
      let ratio = (clickX - boundLeft) / (boundRight - boundLeft)
      let duration = videoRef.current.duration
      videoRef.current.currentTime = duration * ratio
    }
  }
  const send = (response: string) => {
    sendEvent(response)
  }

  return (
    <Flex>
      {!mode ? (
        transcriptOpen ? (
          <Flex
            sx={{
              ...styles.transcriptVideoContainer,
              visibility: ready || played ? 'visible' : 'hidden'
            }}
          >
            <Box
              sx={{
                ...styles.playIconContainer,
                display: !played ? 'flex' : 'none'
              }}
            >
              <PlayIconIdle
                onClick={() => {
                  dispatch({ type: 'videoPlayed', index: index })
                  togglePlay()
                }}
              />
            </Box>
            <video
              ref={videoRef}
              onTimeUpdate={handleProgress}
              width="100%"
              style={{ maxWidth: '15rem', maxHeight: '15rem' }}
              height="100%"
              onLoadedMetadata={() => {
                setReady(true)
              }}
            >
              <source src={source} type="video/mp4" />
            </video>
            {(isPlaying || played) && (
              <Flex sx={styles.controlsContainer}>
                <Box
                  sx={{
                    ...styles.controls
                  }}
                  onClick={togglePlay}
                >
                  {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </Box>
                <progress
                  id="progress"
                  ref={progressRef}
                  value={`${progress}`}
                  style={{
                    width: '100%',
                    alignSelf: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={e => handleClick(e)}
                  max="100"
                >
                  <span id="progress-bar"></span>
                </progress>
                <Box
                  sx={{
                    ...styles.controls
                  }}
                  onClick={toggleFullScreenTranscript}
                >
                  <ExpandIcon />
                </Box>
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex
            sx={{
              ...styles.defaultVideoContainer,
              ml: mediaQueryMobile ? '' : 'auto',
              margin: mediaQueryMobile ? 'auto' : '',
              visibility: ready ? 'visible' : 'hidden'
            }}
          >
            <video
              ref={videoRef}
              onTimeUpdate={handleProgress}
              style={{
                maxWidth: '20rem',
                maxHeight: '20rem',
                height: '100%',
                width: '100%'
              }}
              onLoadedMetadata={() => {
                setReady(true)
              }}
              autoPlay
            >
              <source src={source} type="video/mp4" />
            </video>
            <Flex sx={styles.controlsContainer}>
              <Box
                sx={{
                  ...styles.controls
                }}
                onClick={togglePlay}
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </Box>
              <progress
                id="progress"
                ref={progressRef}
                value={`${progress}`}
                style={{
                  width: '100%',
                  alignSelf: 'center',
                  cursor: 'pointer'
                }}
                onClick={e => handleClick(e)}
                max="100"
              >
                <span id="progress-bar"></span>
              </progress>
              <Box
                sx={{
                  ...styles.controls
                }}
                onClick={toggleFullScreen}
              >
                <ExpandIcon />
              </Box>
            </Flex>
          </Flex>
        )
      ) : (
        <Flex
          sx={{
            ...styles.fullscreenVideoContainer,
            visibility: ready ? 'visible' : 'hidden'
          }}
        >
          <video
            ref={videoRef}
            onTimeUpdate={handleProgress}
            style={{
              margin: 'auto',
              width: '100%',
              height: '100%',
              paddingBottom: '2rem'
            }}
            onLoadedMetadata={() => {
              setReady(true)
            }}
            autoPlay
          >
            <source src={source} type="video/mp4" />
          </video>
          <Flex
            sx={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              display: 'flex',
              ...styles.controlsContainer
            }}
          >
            <Box
              sx={{
                ...styles.controls
              }}
              onClick={togglePlay}
            >
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </Box>
            <progress
              id="progress"
              ref={progressRef}
              value={`${progress}`}
              style={{ width: '100%', alignSelf: 'center', cursor: 'pointer' }}
              onClick={e => handleClick(e)}
              max="100"
            >
              <span id="progress-bar"></span>
            </progress>
            <Box
              sx={{
                ...styles.controls
              }}
              onClick={toggleFullScreen}
            >
              <CompressIcon />
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default Player
