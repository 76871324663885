import React, { useContext, useState } from 'react'
import { Box } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import { ReactComponent as CloseIcon } from '../../assets/img/close.svg'
import { ReactComponent as ZoomInIcon } from '../../assets/img/zoomin.svg'
import { ReactComponent as ZoomOutIcon } from '../../assets/img/zoomout.svg'
import { useMediaQuery } from 'react-responsive'
export interface ImgOverlayProps {
  ZoomIn: () => void
  ZoomOut: () => void
  SetZoom: (action) => void
  Expand: () => void
  Compress: () => void
  ResetTransform: (animationTime, animationName) => void
  SetTransform: (x, y, scale, animationTime, animationName) => void
  CenterView: (Zoom) => void
  NaturalWidth: any
  NaturalHeight: any
  ContainerWidth: any
  ContainerHeight: any
  Zoom: number
  Mode: string
  Ref: any
  ImageRef: any
}
const ImageOverlay: React.FC<ImgOverlayProps> = ({
  Mode,
  Zoom,
  ContainerWidth,
  ContainerHeight,
  NaturalWidth,
  NaturalHeight,
  ZoomIn,
  SetTransform,
  SetZoom,
  Expand,
  Compress,
  ZoomOut,
  Ref,
  ImageRef
}) => {
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const { transcriptOpen } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const [disabled, setDisabled] = useState(false)
  return (
    <Box
      sx={{
        ...styles.imageOverlayBasic
      }}
    >
      <Box
        sx={{
          ...styles.imageOverlayItem,
          marginBottom: '1rem',
          background: 'rgba(0, 0, 0, 0.5)',
          cursor: 'pointer'
        }}
        onClick={() => {
          console.log('Closing gallery')
          dispatch({ type: 'closeGallery' })
        }}
      >
        <CloseIcon />
      </Box>
      <Box
        sx={{
          ...styles.imageOverlayItem,
          opacity: Zoom > 4 ? '0.5' : '1',
          cursor: Zoom > 4 ? 'auto' : 'pointer',
          marginTop: '0px',
          background: 'rgba(0, 0, 0, 0.5)'
        }}
        onClick={() => {
          if (!disabled)
            if (Zoom < 5) {
              setDisabled(true)
              SetZoom('increase')
              ZoomIn()
              setTimeout(() => setDisabled(false), 300)
            }
        }}
      >
        <ZoomInIcon />
      </Box>
      <Box
        sx={{
          ...styles.imageOverlayItem,
          opacity: Zoom > 0 ? '1' : '0.5',
          cursor: Zoom > 0 ? 'pointer' : 'auto',
          background: 'rgba(0, 0, 0, 0.5)'
        }}
        onClick={() => {
          if (!disabled)
            if (Zoom > 0) {
              setDisabled(true)
              SetZoom('decrease')
              ZoomOut()
            }
          setTimeout(() => setDisabled(false), 300)
        }}
      >
        <ZoomOutIcon />
      </Box>
    </Box>
  )
}

export default ImageOverlay
