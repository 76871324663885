import React, { useContext } from 'react'
import { UneeqContext } from 'uneeq-react-core'
import { Button } from 'rebass'
import { ReactComponent as MicIcon } from '../../assets/img/mic-icon.svg'
import { ReactComponent as LoadingIcon } from '../../assets/img/loading.svg'
import { useVolume, useIsSmallScreen } from 'uneeq-react-core'
import Config from 'digital-human/src/config'
import styles from './styles'
import { keyframes } from '@emotion/core'

export interface SendingRecording {
  isDisabled: boolean
  sending: boolean
  recording: boolean
}
const PushToTalk: React.FC<SendingRecording> = ({
  recording,
  sending,
  isDisabled
}) => {
  const config = Config.getInstance()

  const volume = useVolume(recording)
  const backgroundSize = recording ? `${volume * 2}% 100%` : undefined

  const hex2rgba = (hex: string, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  }

  const pulse = (pttMobileGlow: string) => keyframes`
    0% {
      box-shadow: 0px 0px 0px 0.063rem  ${hex2rgba(pttMobileGlow, 0.2)};
    }
    25% {
      box-shadow: 0px 0px 0px 0.375rem  ${hex2rgba(pttMobileGlow, 0.2)};
    }
    50% {
      box-shadow: 0px 0px 0px 10.063rem  ${hex2rgba(pttMobileGlow, 0.2)};
    }
    75% {
      box-shadow: 0px 0px 0px 0.375rem  ${hex2rgba(pttMobileGlow, 0.2)};
    }  
    100% {
      box-shadow: 0px 0px 0px 0.063rem ${hex2rgba(pttMobileGlow, 0.2)};
  }`
  const { state } = useContext(UneeqContext)
  const blocked = state.avatarSpeaking || state.sending || !state.ready
  const blockStyle = () => {
    if (blocked) return { opacity: 0.3, '&:hover': {} }
    return {
      '&:hover': {
        ...config.persona.style.colors.icons.mic.active
      }
    }
  }

  return (
    <Button
      disabled={isDisabled}
      sx={{
        ...styles.bottomBarButton,
        ...styles.bottomBarButtonPush,
        ...config.persona.style.colors.icons.mic.basic,
        ...blockStyle(),
        '&:active': {
          ...config.persona.style.colors.icons.mic.active
        },
        backgroundSize: backgroundSize,
        transition: recording ? '0.2s' : 'all 1s',
        animation: recording
          ? `${pulse(
              config.persona.style.colors.icons.mic.active['backgroundColor']
            )} 1.2s infinite`
          : 'none',
        boxShadow: recording
          ? `0px 0px 0px 0.688rem ${config.persona.style.colors.icons.mic.active['backgroundColor']}`
          : 'none'
      }}
    >
      {sending ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '0.063rem', marginTop: '0.813rem' }}
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle cx="50" cy="50" r="0" fill="none" strokeWidth="2">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1s"
              values="0;40"
              keyTimes="0;1"
              keySplines="0 0.2 0.8 1"
              calcMode="spline"
              begin="0s"
            ></animate>
            <animate
              attributeName="opacity"
              repeatCount="indefinite"
              dur="1s"
              values="1;0"
              keyTimes="0;1"
              keySplines="0.2 0 0.8 1"
              calcMode="spline"
              begin="0s"
            ></animate>
          </circle>
          <circle cx="50" cy="50" r="0" fill="none" strokeWidth="2">
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1s"
              values="0;40"
              keyTimes="0;1"
              keySplines="0 0.2 0.8 1"
              calcMode="spline"
              begin="-0.5s"
            ></animate>
            <animate
              attributeName="opacity"
              repeatCount="indefinite"
              dur="1s"
              values="1;0"
              keyTimes="0;1"
              keySplines="0.2 0 0.8 1"
              calcMode="spline"
              begin="-0.5s"
            ></animate>
          </circle>
        </svg>
      ) : (
        <MicIcon />
      )}
    </Button>
  )
}

export default PushToTalk
