const styles = {
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    '& > svg': { width: '100vw', height: '100vh' }
  },
  galleryBox: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 26
  },
  imageOverlayBasic: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    height: '8.5rem',
    width: '2.5rem',
    transform: 'translateY(-50%)',
    top: '50%',
    right: '0px',
    zIndex: 27
  },
  imageOverlayItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2.5rem',
    height: '2.5rem',
    padding: '0.5rem',
    '& > svg': {
      fill: 'white',
      color: 'white',
      height: '1.5rem',
      width: '1.5rem'
    }
  },
  Overlay: {
    left: 0,
    zIndex: 0,
    top: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
    background: 'linear-gradient(135deg, #2D373C 0%, #48BAAC 100%)',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

export default styles
