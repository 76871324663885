import { Input, Label } from '@rebass/forms'
import React, { useContext, useState } from 'react'
import { Flex } from 'rebass'
import { trackEvent, trackHandler, UneeqContext } from 'uneeq-react-core'
import { ReactComponent as SendIcon } from '../../assets/img/send-message.svg'
import styles from './styles'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
interface TranscriptFooterProps {
  setHasText: (hasText: boolean) => void
}
const TranscriptFooter: React.FC<TranscriptFooterProps> = ({ setHasText }) => {
  const [text, _setText] = useState('')
  const { sendText } = useContext(UneeqContext)
  const { t } = useTranslation()

  const setText = (text: string) => {
    _setText(text)
    setHasText(!!text)
  }

  const send = () => {
    sendText(text)
    setText('')
  }

  const { state } = useContext(UneeqContext)
  const blocked = state.recording || state.avatarSpeaking || state.sending

  return (
    <Flex className="transcript-footer" sx={{
        ...styles.footer,
        ...blocked ? { opacity: 0.5 } :  { opacity: 1 }  
    }}>
      <Label sx={styles.questionInputLabel} htmlFor="questionInput">
        Question
      </Label>
      <Input
        placeholder={t('TranscriptFooter.inputPlaceholder')}
        value={text}
        id="questionInput"
        data-testid="questionInput"
        onChange={e => setText(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') { send() }
        }}
        disabled = { blocked }
        sx={styles.textInput}
      />
    </Flex>
  )
}

export default TranscriptFooter
