import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import React, {
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  useState
} from 'react'
import styles from './styles'
import { Flex, Box } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import ImageOverlay from './ImageOverlay'
import { ReactComponent as LoadingIcon } from '../../assets/img/loading.svg'
import { useMediaQuery } from 'react-responsive'
export const ImageGallery: React.FC<any> = (args: any) => {
  const { galleryIndex, imageGallery } = useUneeqState()
  const [Zoom, setZoom] = useState<number>(0)
  const [Mode, setMode] = useState('contain')
  const { dispatch } = useContext(UneeqContext)
  const Test = useRef<any>()
  function setZoomState(zoom) {
    switch (zoom) {
      case 'increase': {
        console.log('Zooming in')
        setZoom(Zoom => ~~Zoom + 1)
        break
      }
      case 'decrease': {
        console.log('Zooming out')
        setZoom(Zoom => ~~Zoom - 1)
        break
      }
      case 'reset': {
        console.log('Resetting zoom')
        setZoom(0)
        break
      }
    }
  }
  function Expand() {
    if (Mode !== 'expand') setMode('expand')
  }
  function Compress() {
    setMode('contain')
  }
  const [src, setSrc] = useState()
  const alt = 'image'
  const scaleUp = true
  const zoomFactor = 5
  const element = useRef<any>()
  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  const [containerWidth, setContainerWidth] = useState<number>(0)
  const [containerHeight, setContainerHeight] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0)
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0)

  const imageScale = useMemo(() => {
    if (
      containerWidth === 0 ||
      containerHeight === 0 ||
      imageNaturalWidth === 0 ||
      imageNaturalHeight === 0
    )
      return 0
    const scale = Math.min(
      containerWidth / imageNaturalWidth,
      containerHeight / imageNaturalHeight
    )
    return scaleUp ? scale : Math.max(scale, 1)
  }, [
    scaleUp,
    containerWidth,
    containerHeight,
    imageNaturalWidth,
    imageNaturalHeight
  ])

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect()
      setContainerWidth(rect.width)
      setContainerHeight(rect.height)
    } else {
      setContainerWidth(0)
      setContainerHeight(0)
    }
  }, [container])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  const handleImageOnLoad = (image: HTMLImageElement) => {
    setImageNaturalWidth(image.naturalWidth)
    setImageNaturalHeight(image.naturalHeight)
  }
  useEffect(() => {
    setSrc(imageGallery.get(galleryIndex)?.source)
  }, [galleryIndex, imageGallery])

  useEffect(() => {
    setLoading(true)
    const image = new Image()
    setImageNaturalWidth(0)
    setImageNaturalHeight(0)
    image.onload = () => {
      handleImageOnLoad(image)
      setLoading(false)
    }
    image.src = src!
  }, [src])

  return (
    <Box
      sx={{
        ...styles.galleryBox
      }}
      ref={(el: HTMLDivElement | null) => setContainer(el)}
    >
      {imageScale > 0 && (
        <TransformWrapper
          key={`${containerWidth}x${containerHeight}`}
          initialScale={imageScale}
          minScale={imageScale}
          maxScale={imageScale * zoomFactor}
          centerOnInit={true}
          wheel={{ disabled: true }}
          alignmentAnimation={{ disabled: true, sizeX: 0, sizeY: 0 }}
          zoomAnimation={{ disabled: true }}
          panning={{ velocityDisabled: true }}
          doubleClick={{ disabled: true }}
        >
          {({
            zoomIn,
            zoomOut,
            resetTransform,
            setTransform,
            centerView,
            ...rest
          }) => (
            <React.Fragment>
              <ImageOverlay
                ContainerWidth={containerWidth}
                ContainerHeight={containerHeight}
                NaturalWidth={imageNaturalWidth}
                NaturalHeight={imageNaturalHeight}
                ZoomIn={zoomIn}
                SetZoom={setZoomState}
                CenterView={centerView}
                ResetTransform={resetTransform}
                SetTransform={setTransform}
                ZoomOut={zoomOut}
                Expand={Expand}
                Compress={Compress}
                Zoom={Zoom}
                Ref={Test}
                ImageRef={element}
                Mode={Mode}
              />
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '100%'
                }}
              >
                {loading ? (
                  <LoadingIcon
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%'
                    }}
                  />
                ) : (
                  <img
                    alt={alt}
                    src={src}
                    ref={element}
                    width={imageNaturalWidth}
                    height={imageNaturalHeight}
                  />
                )}
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      )}
    </Box>
  )
}
export default ImageGallery
