import { Flex, Text } from 'rebass'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'
import React, { useState, useContext } from 'react'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import { ReactComponent as CircleWhiteIcon } from '../../assets/img/circle.svg'
import { ReactComponent as CircleCheckedIcon } from '../../assets/img/circleChecked.svg'

//Component used to test html in Home instead of need wait, used just for development
const CaptionController: React.FC = () => {
  const { t } = useTranslation()
  const { dispatch } = useContext(UneeqContext)
  const { captionVisible } = useUneeqState()
  const [captionState, setCaptionState] = useState(
    captionVisible ? 'Default' : 'Disabled'
  )

  console.log('captionState', captionState)
  const disableCaption = () => {
    setCaptionState('Disabled')
    dispatch({ type: 'captionVisibility', payload: false })
  }
  const enableCaption = () => {
    setCaptionState('Default')
    dispatch({ type: 'captionVisibility', payload: true })
  }

  return (
    <Flex sx={styles.captionWrapper}>
      <Flex onClick={disableCaption} sx={styles.captionActionWrapper}>
        <Flex sx={styles.captionIcon}>
          {captionState === 'Disabled' ? (
            <CircleCheckedIcon style={{ fontSize: '1.25rem' }} />
          ) : (
            <CircleWhiteIcon style={{ fontSize: '1.25rem' }} />
          )}
        </Flex>

        <Text
          style={{
            fontSize: '1rem'
          }}
        >
          {t('Menu.captionDisableText')}
        </Text>
      </Flex>
      <Flex onClick={enableCaption} sx={styles.captionActionWrapper}>
        <Flex sx={styles.captionIcon}>
          {captionState === 'Default' ? (
            <CircleCheckedIcon color="black" style={{ fontSize: '1.25rem' }} />
          ) : (
            <CircleWhiteIcon color="white" style={{ fontSize: '1.25rem' }} />
          )}
        </Flex>

        <Text
          style={{
            fontSize: '1rem'
          }}
        >
          {t('Menu.captionDefaultText')}
        </Text>
      </Flex>
    </Flex>
  )
}

export default CaptionController
