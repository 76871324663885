import React, { useContext } from 'react'
import { UneeqContext, useTranscript } from 'uneeq-react-core'
import { Flex } from 'rebass'
import { motion, AnimatePresence } from 'framer-motion'
import InformationContent from './InformationContent'
import { InformationItem } from './InformationTypes'
interface InformationProps {
  information: InformationItem[]
}
const MotionFlex = motion.custom(Flex)

const styles = {
  textContainer: {
    marginRight: '2.5rem'
  },
  textStyle: {
    background: 'transparent',
    borderBottomRightRadius: '3.125rem',
    fontFamily: 'RocheSans-Light',
    fontWeight: '700',
    fontSize: '1.5rem',
    lineHeight: '2.188rem',
    marginBottom: '0.75rem',
    color: '#01426A',
    textShadow:
      '-0.5px -0.5px 5px rgb(255 255 255 / 60%), 0.5px -0.5px 0.313rem rgb(255 255 255 / 60%), -0.5px 0.5px 0.313rem rgb(255 255 255 / 60%), 0.5px 0.5px 0.313rem rgb(255 255 255 / 60%)'
  }
}

const Transliteration: React.FC<InformationProps> = ({ information }) => {
  let { transcript } = useTranscript()
  const botMessages = transcript.filter(t => !t.user && t.message)
  const { state } = useContext(UneeqContext)
  return (
    <Flex sx={{ flexDirection: 'column', opacity: !state.sending ? 1 : 0 }}>
      <AnimatePresence>
        {botMessages.length && (
          <MotionFlex
            variants={{
              start: {
                opacity: 0,
                transition: {
                  delay: 1
                },
                transform: 'translateX(-200px)'
              },
              end: {
                opacity: 1,
                transition: {
                  delay: 1
                },
                transform: 'translateX(0.063rem)'
              },
              final: {
                opacity: 0,
                transform: 'translateX(200px)'
              }
            }}
            initial="start"
            animate="end"
            exit="final"
            key="question"
          >
            <div style={styles.textContainer}>
              {botMessages[botMessages.length - 1].message
                .split('::')
                .map((line: string, i: number) => {
                  return (
                    <div
                      style={{ ...styles.textStyle, textAlign: 'left' }}
                      key={i}
                    >
                      {line}
                    </div>
                  )
                })}
            </div>
          </MotionFlex>
        )}
      </AnimatePresence>
      <Flex sx={{ opacity: !state.avatarSpeaking ? 1 : 0 }}>
        <InformationContent information={information} />
      </Flex>
    </Flex>
  )
}

export default Transliteration
