const styles = {
  container: {
    label: 'logo-container',
    position: 'absolute',
    left: '0.938rem',
    top: '1.25rem',
    zIndex: 99,
    display: [
      'none !important',
      'none !important',
      'none !important',
      'block !important',
      'block !important',
      'block !important'
    ]
  }
}

export default styles
