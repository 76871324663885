import React, { useContext } from 'react'
import { Flex } from 'rebass'
import styles from './styles'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'

interface LogoProps {
  svg?: string
  url?: string
  link?: string
  fill?: string
  handleClick?: React.MouseEventHandler
}
const Logo: React.FC<LogoProps> = ({ ...props }) => {
  const { galleryOpen } = useUneeqState()
  const { config } = useContext(UneeqContext)
  return (
    <>
      <Flex
        sx={{
          ...styles.container,
          ':hover': {
            boxShadow: 'none',
            cursor: props.link ? 'pointer' : 'default'
          },
          fill: galleryOpen
            ? config?.persona.style.colors.galleryMode?.fill
            : config?.persona.style?.logo?.fill
        }}
        onClick={props.handleClick}
      >
        {props.svg && (
          <div dangerouslySetInnerHTML={{ __html: props.svg || '' }} />
        )}
        {props.url && <img src={props.url} />}
      </Flex>
    </>
  )
}

export default Logo
