const styles = {
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    zIndex: 10000
  },
  main: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media(max-width: 900px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  emptyColumn: {
    '@media(max-width: 900px)': {
      display: 'none'
    }
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    '@media(max-width: 900px)': {
      width: '90%'
    }
  },
  inputContainer: {
    width: '60%',
    textAlign: 'center',
    margin: '0 auto',
    marginTop: '1.25rem',
    '@media(max-width: 900px)': {
      width: '80%'
    }
  },
  input: {
    background: '#fff',
    borderRadius: '0.625rem',
    borderColor: '#fff',
    '&::placeholder': { opacity: '0.6' },
    zIndex: 1,
    '&:focus': {
      outline: 'none'
    }
  },
  label: {
    margin: '0.625rem 0 0.625rem',
    color: '#fff',
    fontSize: "1rem"
  },
  textArea: {
    background: '#fff',
    borderRadius: '0.625rem',
    borderColor: '#fff',
    minHeight: '9.375rem',
    '&::placeholder': { opacity: '0.6' },
    zIndex: 1,
    '&:focus': {
      outline: 'none'
    }
  },
  errorMessage: {
    color: '#fff',
    background: "#B40000",
    fontFamily: 'Roche Sans',
    fontWeight: '500',
    fontSize: '1rem',
    textAlign: 'left',
    padding: "0.313rem",
    borderRadius: "0.313rem",
    marginTop: "0.625rem"

  },
  closeIcon: {
    position: 'absolute',
    top: '1.875rem',
    right: '1.25rem',
    color: '#fff',
    '& > svg': {
      pointer: 'cursor',
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: '10%',
    textAlign: 'right'
  },
  sendButton: {
    color: '#fff',
    fontSize: "1rem"
  },
  sentModalContainer: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%'
    // backdropFilter: 'blur(1.188rem)',
  },
  sentModal: {
    position: 'absolute',
    background: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    borderRadius: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '1.25rem'
  },
  sentButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.625rem',
    background: '#48BAAC',
    boxShadow:
      '0px 0.75rem 1rem rgba(194, 186, 181, 0.16), 0px 0.25rem 0.5rem rgba(84, 79, 79, 0.16)',
    borderRadius: '0.625rem',
    width: '80%',
    marginTop: '1.25rem',
    '@media(max-width: 700px)': {
      width: '100%'
    }
  }
}
export default styles
