const styles = {
  bar: {
    label: 'bottomBar',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 30,
    '& div::-webkit-scrollbar': {
      display: 'none'
    },
    '& div': {
      msOverflowStyle: 'none',
      scrollbarWidth: 'none'
    },
    minHeight: '6.25rem'
  },
  leaveMessage: {
    right: 0,
    position: 'absolute',
    ml: '0.625rem',
    flex: 1,
    p: 5,
    alignItems: 'center'
  },
  menuItemLabel: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    textAlign: 'right',
    marginTop: '1.25rem',
    boxShadow: 'none',
    width: '3.75rem',
    height: '3.75rem',
    '& > svg': {
      height: [28, 32, 34, 38, 40, 40],
      width: [28, 32, 34, 38, 40, 40],
      margin: 0
    }
  },
  lowerBg: {
    position: 'fixed',
    visibility: ['hidden', 'hidden', 'hidden', 'hidden', 'visible', 'visible'],
    bottom: 0,
    left: 0,
    zIndex: 0,
    width: '100%',
    maxHeight: 280,
    opacity: 0.5
  },
  backButton: {
    boxShadow: 'none',
    background: 'none',
    width: '4rem',
    height: '4rem'
  },
  nextButton: {
    boxShadow: 'none',
    width: '4rem',
    height: '4rem'
  },
  userQuestionMotionContainer: {
    label: 'userQuestionContainer',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100vw',
    zIndex: 31,
    fontFamily: 'RocheSans',
    textShadow: '0.063rem 0.063rem 0.125rem #000000'
  },
  switchStyle: {
    cursor: 'pointer',
    position: 'absolute',
    color: '#71c8dc',
    top: 64,
    left: 50,
    mr: 50,
    border: '0.063rem solid #B1B3B3',
    backgroundColor: '#B1B3B3',
    '& div': {
      backgroundColor: '#B1B3B3',
      border: '0.063rem solid #71c8dc',
      color: '#71c8dc'
    }
    // '@media(orientation: landscape) and (max-width: 1100px)': {
    //   top: 85,
    //   left: 40
    // },
    // //iPhone 5
    // '@media(orientation: landscape) and (min-width: 566px) and (max-width: 570px)': {
    //   top: 80,
    //   left: 37
    // },
    // //Galaxy Fold
    // '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
    //   top: 110,
    //   left: 40
    // },
    // '@media(min-width: 275px) and (max-width: 285px)': {
    //   top: 55,
    //   left: 29
    // }
  },
  switchTextStyle: {
    position: 'absolute',
    top: 40,
    left: 25,
    color: '#1a404a'

    // // fontFamily: 'Berthold Imago',
    // '@media(orientation: landscape) and (max-width: 1100px)': {
    //   top: 65,
    //   left: 5
    //   //untertitel
    // },
    // //iPhone 5
    // '@media(orientation: landscape) and (min-width: 566px) and (max-width: 570px)': {
    //   top: 60,
    //   left: 3
    // },
    // //Galaxy Fold
    // '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
    //   top: 90,
    //   left: 5
    // },
    // '@media(min-width: 275px) and (max-width: 285px)': {
    //   top: 31,
    //   left: 1
    // }
  },
  opacity: {
    opacity: 0,
    display: 'inline-block',
    backgroundColor: '#71c8dc'
  },
  phoneCall: {
    top: ['-22rem', '-22rem', '-22rem', '-22rem', '3.125rem', '3.125rem'],
    left: [
      '0.938rem',
      '0.938rem',
      '0.938rem',
      '0.938rem',
      '13.125rem',
      '13.125rem'
    ],
    position: 'absolute',
    width: ['1.8rem', '1.8rem', '1.8rem', '1.8rem', '2rem', '2rem'],
    height: ['1.8rem', '1.8rem', '1.8rem', '1.8rem', '2rem', '2rem'],
    borderRadius: 50,
    '@media(orientation: landscape) and (max-width: 1100px)': {
      top: 85,
      left: 180,
      zIndex: 2
    },
    //iPhone 5
    '@media(orientation: landscape) and (min-width: 566px) and (max-width: 570px)': {
      top: 78,
      left: 170,
      zIndex: 2
    },
    //Galaxy Fold
    '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
      top: 105,
      left: 180,
      zIndex: 2
    }
  },
  email: {
    top: ['-19rem', '-19rem', '-19rem', '-19rem', '3.125rem', '3.125rem'],
    left: [
      '0.938rem',
      '0.938rem',
      '0.938rem',
      '0.938rem',
      '10.625rem',
      '10.625rem'
    ],
    position: 'absolute',
    width: ['1.8rem', '1.8rem', '1.8rem', '1.8rem', '2rem', '2rem'],
    height: ['1.8rem', '1.8rem', '1.8rem', '1.8rem', '2rem', '2rem'],
    borderRadius: 50,
    '@media(orientation: landscape) and (max-width: 1100px)': {
      top: 85,
      left: 140,
      zIndex: 2
    },
    //iPhone 5
    '@media(orientation: landscape) and (min-width: 566px) and (max-width: 570px)': {
      top: 78,
      left: 130,
      zIndex: 2
    },
    //Galaxy Fold
    '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
      top: 105,
      left: 140,
      zIndex: 2
    }
  },
  iconsBox: {
    height: '5rem',
    width: '31.25rem',
    color: '#FFFFFF',
    top: ['-25rem', '-25rem', '-25rem', '-25rem', '3.125rem', '3.125rem'],
    left: ['0px', '0px', '0px', '0px', '2.5rem', '2.5rem'],
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    ml: [0, 0, 0, 0, 50, 50],
    position: 'absolute'
    // '@media(orientation: landscape) and (max-width: 1100px)': {
    //   top: 85,
    //   left: 40,
    //   zIndex: 2
    // },
    // // //iPhone 5
    // // '@media(orientation: landscape) and (min-width: 566px) and (max-width: 570px)': {
    // //   top: 78,
    // //   left: 40,
    // //   zIndex: 2
    // // },
    // // //Galaxy Fold
    // // '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
    // //   top: 105,
    // //   left: 40,
    // //   zIndex: 2
    // // }
  },
  iconItem: {
    marginLeft: '1.563rem',
    mt: '0.625rem',
    width: 'auto'
  },
  chat: {
    top: ['-25rem', '-25rem', '-25rem', '-25rem', '3.125rem', '3.125rem'],
    left: [
      '0.938rem',
      '0.938rem',
      '0.938rem',
      '0.938rem',
      '15.625rem',
      '15.625rem'
    ],
    position: 'absolute',
    width: ['1.8rem', '1.8rem', '1.8rem', '1.8rem', '2rem', '2rem'],
    height: ['1.8rem', '1.8rem', '1.8rem', '1.8rem', '2rem', '2rem'],
    cursor: 'pointer',
    '@media(orientation: landscape) and (max-width: 1100px)': {
      top: 85,
      left: 220,
      zIndex: 2
    },
    //iPhone 5
    '@media(orientation: landscape) and (min-width: 566px) and (max-width: 570px)': {
      top: 78,
      left: 210,
      zIndex: 2
    },
    //Galaxy Fold
    '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
      top: 105,
      left: 220,
      zIndex: 2
    }
  },
  captionsContainer: {
    mx: 'auto',
    width: 'fit-content',
    position: 'absolute',
    display: 'flex',
    zIndex: 35,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  sideEffect: {
    top: ['-16rem', '-16rem', '-16rem', '-16rem', '3.125rem', '3.125rem'],
    left: [
      '0.938rem',
      '0.938rem',
      '0.938rem',
      '0.938rem',
      '8.125rem',
      '8.125rem'
    ],
    position: 'absolute',
    width: ['1.8rem', '1.8rem', '1.8rem', '1.8rem', '2rem', '2rem'],
    height: ['1.8rem', '1.8rem', '1.8rem', '1.8rem', '2rem', '2rem'],
    '@media(orientation: landscape) and (max-width: 1100px)': {
      top: 85,
      left: 100,
      zIndex: 2
    },
    //iPhone 5
    '@media(orientation: landscape) and (min-width: 566px) and (max-width: 570px)': {
      top: 78,
      left: 90,
      zIndex: 2
    },
    //Galaxy Fold
    '@media(orientation: landscape) and (min-width: 650px) and (max-width: 655px)': {
      top: 105,
      left: 100,
      zIndex: 2
    }
  },
  buttonCallMe: {
    top: [
      '-4.063rem',
      '-4.063rem',
      '-4.063rem',
      '-4.063rem',
      '3.125rem',
      '3.125rem'
    ],
    left: [
      '1.875rem',
      '1.875rem',
      '1.875rem',
      '1.875rem',
      '11.875rem',
      '11.875rem'
    ],
    position: 'absolute',
    borderRadius: 50,
    border: '0.063rem solid white',
    backgroundColor: '#B1B3B3',
    color: 'white',
    '& a': {
      textDecoration: 'none',
      color: 'white'
    }
  },
  buttonMailMe: {
    top: [
      '-0.938rem',
      '-0.938rem',
      '-0.938rem',
      '-0.938rem',
      '3.125rem',
      '3.125rem'
    ],
    left: [
      '1.875rem',
      '1.875rem',
      '1.875rem',
      '1.875rem',
      '8.75rem',
      '8.75rem'
    ],
    position: 'absolute',
    borderRadius: 50,
    border: '0.063rem solid white',
    backgroundColor: '#B1B3B3',
    color: 'white',
    '& a': {
      textDecoration: 'none',
      color: 'white'
    }
  },
  // motionSuggestedResponses: {
  //   position: 'absolute',
  //   top: 120,
  //   height: 95,
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'flex-end'
  // },
  center: {
    label: 'bottomBarCenter',
    flexDirection: 'column',
    maxWidth: '100%',
    justifyContent: 'flex-end',
    width: '100%'
  },
  mobileChatContainer: {
    display: ['flex', 'flex', 'flex', 'flex', 'none'],
    width: ['100%', '100%', '100%', '100%', 'auto', 'auto']
  },
  bigScreenChatContainer: {
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    width: ['100%', '100%', '100%', '100%', 'auto', 'auto']
  },
  chatAndInputToggleContainer: {
    alignItems: [
      'flex-end',
      'flex-end',
      'flex-end',
      'flex-end',
      'center',
      'center'
    ],
    width: ['100%', '100%', '100%', '100%', 'auto', 'auto']
  },
  userQuestionContainer: {
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'blue'
  },

  right: {
    label: 'right',
    position: 'absolute',
    right: 0,
    flex: 1,
    pr: '10px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 1
  },
  left: {
    label: 'left',
    position: 'absolute',
    left: 0,
    flex: 1,
    pl: '10px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    zIndex: 1
  },
  chatSavedItemsContainer: {
    label: 'chatSavedItemsContainer',
    position: 'absolute',
    right: 40,
    bottom: 40,
    zIndex: 6
  },
  chatBubbleContainer: {
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    cursor: 'pointer',
    color: 'primary',
    alignItems: 'center',
    ml: 6,
    '& svg': {
      mr: 1,
      mt: '0.125rem'
    }
  },
  skip: {
    label: 'skip',
    width: 204,
    height: 50,
    minHeight: 50,
    mb: [2, 2, 2, 2, 0]
  },
  pttOuterContainer: {
    justifyContent: 'center',
    minHeight: [40, 40, 70, 70, 50, 50],
    mb: [0, 0, 0, 0, 4, 4],
    width: '100%',
    alignItems: 'center'
  },
  pttContainer: {
    label: 'pttContainer',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '@media(orientation: landscape) and (max-width: 825px)': {
      position: 'absolute',
      zIndex: 50,
      width: '15rem',
      height: '2.5rem'
    }
  },
  emptyContainer: {
    minWidth: [30, 30, 40, 40, 0, 0],
    height: [30, 30, 40, 40, 0, 0],
    mr: [10, 10, 10, 10, 0, 0]
  },
  mobileContainer: {
    width: [30, 30, 40, 40, 0, 0],
    mb: '0.625rem'
  },
  pushToTalkButton: {
    label: 'pushToTalkButton',
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'none',
    justifyContent: 'center',
    left: '50%',
    fontWeight: 'bold',
    alignItems: 'center',
    pt: 0,
    pl: 0,
    pr: 0,
    margin: 0,
    '& > svg': {
      height: [28, 32, 34, 38, 40, 40],
      width: [28, 32, 34, 38, 40, 40],
      margin: 0
    },
    height: [48, 52, 56, 60, 64, 72],
    width: [48, 52, 56, 60, 64, 72],
    my: [0, 0, 0, 0, 0, 0],
    mx: [0, 0, 0, 0, 0, 0],
    fontFamily: 'RocheSans-Light'
  },
  // pushToTalkText: {
  //   display: ['none', 'none', 'none', 'none', 'inline-flex', 'inline-flex']
  // },

  bottomBarButtonContainer: {},
  mobileChatBtn: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  bottomBarButton: {
    color: '#FFFFFF',
    zIndex: 10,
    background: 'none',
    height: '4rem',
    width: '4rem',
    ml: '8px',
    mt: 0,
    pt: 0,
    pl: 0,
    pr: 0,
    borderRadius: '50%',
    fontFamily: 'RocheSans-Light',
    '@media(max-width: 768px)': {
      ml: 0
    }
  },
  bottomBarButtonPush: {
    '& > svg': {
      marginLeft: '1.1rem',
      marginTop: '1rem',
      height: '2rem',
      width: '2rem'
    }
  },
  bottomBarButtonChat: {
    pl: '0.875rem',
    pt: '0.438rem',
    // '& > svg': {     moved to inline
    //   ml: '0.438rem',
    //   mt: '0.625rem',
    //   height: '2.5rem',
    //   width: '2.5rem'
    // },
    '& :hover': {
      boxShadow: 'none'
    }
  },
  inputToggleText: {
    display: ['none', 'none', 'none', 'none', 'inline-flex', 'inline-flex']
  },
  pushToTalkText: {
    display: ['none', 'none', 'none', 'none', 'inline-flex', 'inline-flex']
  },
  mobilePrompt: {
    position: 'absolute',
    bottom: 120,
    width: '100%',
    left: 0,
    color: 'white',
    justifyContent: 'center',
    display: ['flex', 'none'],
    fontSize: 25
  },
  talkButton: {
    label: 'talkButton',
    display: ['block', 'none'],
    borderRadius: '62.5rem',
    width: '4.438rem',
    height: '4.438rem',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'none',
    mt: -45,
    div: {
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '2.5rem',
      transition: 'none'
    }
  },
  chatContainer: {
    height: 50,
    width: ['100%', '100%', '100%', '100%', 310, 310],
    position: 'relative'
  },
  chatLabel: {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '0.063rem',
    height: '0.063rem',
    overflow: 'hidden'
  },
  chatTextInput: {
    fontFamily: 'body',
    border: 'none',
    fontSize: 2,
    pr: 14,
    borderRadius: ['none', 'none', 'none', 'button'],
    backgroundColor: 'white',
    color: 'text',
    '&::placeholder': {
      opacity: '0.6'
    },
    '&:focus': {
      borderColor: '#57bfd7'
    },
    width: '100%',
    '@media(orientation: landscape) and (max-width: 1100px)': {
      zIndex: 3
    }
  },
  chatTextInputAr: {
    fontFamily: 'body',
    textAlign: 'right',
    border: 'none',
    fontSize: 2,
    pl: 14,
    borderRadius: ['none', 'none', 'none', 'button'],
    backgroundColor: 'white',
    color: 'text',
    '&::placeholder': {
      opacity: '0.6'
    },
    width: '100%',
    '@media(orientation: landscape) and (max-width: 1100px)': {
      zIndex: 3
    }
  },
  chatSendButton: {
    color: '#57bfd7',
    width: 37,
    height: 37,
    cursor: 'pointer',
    position: 'absolute',
    right: '0.438rem',
    top: '0.438rem',
    '@media(orientation: landscape) and (max-width: 1100px)': {
      zIndex: 3
    }
  },
  chatSendButtonAr: {
    color: 'primary',
    width: 37,
    height: 37,
    cursor: 'pointer',
    position: 'absolute',
    left: '0.438rem',
    top: '0.438rem',
    '@media(orientation: landscape) and (max-width: 1100px)': {
      zIndex: 3
    }
  },
  toggleContainer: {
    position: 'absolute',
    right: 25,
    bottom: 37,
    display: ['none', 'flex'],
    alignItems: 'center',
    cursor: 'pointer'
  },
  exitSessionIcon: {
    position: 'fixed',
    top: 30,
    cursor: 'pointer',
    width: 24,
    display: ['block', 'none']
  },
  noInputButtonContainer: {
    label: 'noInputButtonContainer',
    alignItems: 'center',
    justifyContent: 'center',
    pb: '0.625rem',
    display: ['none', 'none', 'none', 'grid'],
    boxSizing: 'border-box',
    margin: '0px 0px 0px 1rem',
    minWidth: '0px',
    appearance: 'none',
    textAlign: 'center',
    lineHeight: 'inherit',
    textDecoration: 'none',
    fontSize: 'inherit',
    padding: '0px 0px 0px',
    cursor: 'pointer',
    border: '0px',
    zIndex: '10',
    height: '4rem',
    width: '4rem',
    borderRadius: '50%',
    fontFamily: 'RocheSans-Light',
    fill: 'rgb(255, 255, 255)',
    boxShadow: 'none !important',
    webkitBoxAlign: 'center',
    webkitBoxPack: 'center'
  }
}

export default styles
