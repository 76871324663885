import React, { useContext, useEffect } from 'react'
import styles from './styles'
import {
  UneeqProvider,
  UneeqAvatar,
  UneeqLocalVideo,
  UneeqContext,
  useUneeqState,
  trackHandler,
  Uneeq
} from 'uneeq-react-core'
import { Box, Flex } from 'rebass'
import BottomBar from './Components/BottomBar'
import TopBar from './Components/TopBar/TopBar'
import OnScreenInfo from './Components/OnScreenInfo'
import Transcript from './Components/Transcript'
import Errors from './Components/Errors'
import Settings from './Components/Settings'
import Timeout from './Components/Timeout'
import Feedback from './Components/endSession/Feedback'
import EscalationForm from './Components/endSession/EscalationForm'
import PrivacySummary from './Components/PrivacySummary'
import PermissionsGateway, {
  LoadingTip
} from './Components/Startup/PermissionsGateway'
import { motion, AnimatePresence } from 'framer-motion'
import EndSessionConfirm from './Components/endSession/EndSessionConfirm'
import { UneeqCoreConfig } from 'uneeq-react-core'
import Logo from './Components/Logo'
import { useMediaQuery } from 'react-responsive'
import TextSizeChanger from './Components/Menu/TextSizeChanger'
import Menu from './Components/Menu/Menu'
import LeaveFeedback from './Components/Feedback/LeaveFeedback'
import VideoPlayer from './Components/OnScreenInfo/VideoPlayer'
import ImageGallery from './Components/ImageGallery/ImageGallery'
import Captions from './Components/BottomBar/Captions'
const MotionBox = motion.custom(Box)

const DigitalHumanContent = ({
  restart,
  CustomFeedback,
  permissions,
  audio,
  loadingTips
}: any) => {
  const FinalFeedback = CustomFeedback || Feedback
  const { dispatch, config } = useContext(UneeqContext)

  const {
    feedbackOpen,
    transcriptOpen,
    inputMode,
    galleryOpen,
    onScreenInfo,
    videoOpen,
    videoFullscreen,
    videoIndex,
    videoSource,
    supportFeedbackOpen,
    loadingPercentage
  } = useUneeqState()

  const speechMode = inputMode === 'speech'
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const isChatEnabled = !speechMode && mediaQueryMobile
  const { state } = useContext(UneeqContext)
  const toggleModalityMode = () => {
    if (speechMode) {
      dispatch({ type: 'setInputMode', payload: 'text' })
      openTranscript()
    } else {
      dispatch({ type: 'setInputMode', payload: 'speech' })
      closeTranscript()
    }
  }
  const openTranscript = () =>
    dispatch({ type: 'openTranscript', payload: true })
  const closeTranscript = () => dispatch({ type: 'closeTranscript' })

  const isDisabled = () =>
    state.recording || state.avatarSpeaking || state.sending
  const container = isChatEnabled
    ? {
        ...styles.container1,
        background: config.persona.style.home.background.chatBackground
          ? config.persona.style.home.background.chatBackground
          : config.persona.style.home.background.mobile
      }
    : {
        ...styles.container,
        background: '#000',
        backdropFilter: 'blur(1.188rem)'
      }

  return (
    <Box
      sx={
        config.persona.name === 'Diana'
          ? {
              ...container,
              background: config.persona.style.home.background.chatBackground
            }
          : { ...container }
      }
    >
      {config.persona.features.logoVisible == true && (
        <Logo
          svg={config?.persona.style?.logo?.svg}
          url={config?.persona.style?.logo?.url}
          link={config?.persona.style?.logo?.link}
          handleClick={() => {
            config?.persona.style?.logo?.link &&
              window.open(config?.persona.style?.logo?.link, '_blank')
          }}
        />
      )}
      {galleryOpen && (
        <Flex
          sx={{
            ...styles.galleryOverlay,
            background: config.persona.style.home.background.chatBackground
          }}
        >
          <Box
            sx={{
              ...styles.galleryOverlayFilter
            }}
          />
        </Flex>
      )}
      <UneeqAvatar />
      {/* Must be present but we want it hidden */}
      <UneeqLocalVideo style={styles.localVideo} />
      <PermissionsGateway
        restart={restart}
        loadingTips={loadingTips}
        video={permissions}
      >
        {videoOpen && <VideoPlayer fullscreen={true} index={videoIndex} />}
        {galleryOpen ? (
          <ImageGallery />
        ) : !transcriptOpen ? (
          (!state.avatarSpeaking && onScreenInfo.information) ||
          config.persona.name == 'Diana' ? (
            <OnScreenInfo />
          ) : (
            (Array.isArray(onScreenInfo.information)
              ? onScreenInfo.information?.some(item => item.source)
              : onScreenInfo.information?.source) && <OnScreenInfo />
          )
        ) : (
          <Box sx={{ opacity: 0 }}>
            <OnScreenInfo />
          </Box>
        )}
        {supportFeedbackOpen && <LeaveFeedback />}
        <Transcript />
        <Captions />
        {!supportFeedbackOpen && !videoFullscreen && !videoOpen && (
          <BottomBar />
        )}
        {!videoFullscreen && mediaQueryMobile ? (
          <TopBar restart={restart} />
        ) : (
          <Box
            sx={{
              ...styles.settingsContainer
            }}
          >
            <Menu restart={restart} />
          </Box>
        )}
        <Settings audio={audio} />
        <Timeout />
        <PrivacySummary />
        <EndSessionConfirm restart={restart} />
        <FinalFeedback
          restart={restart}
          close={() => dispatch({ type: 'openFeedback', payload: false })}
          isOpen={feedbackOpen}
        />
        <EscalationForm restart={restart} />
        <Errors />
      </PermissionsGateway>
    </Box>
  )
}

interface DigitalHumanProps {
  assets: any
  onTimedOut: () => void
  onSessionEnded: () => void
  config: Partial<UneeqCoreConfig>
  postInit?: (uneeq: Uneeq) => void
  token?: string
  restart: () => void
  loadingTips: Array<LoadingTip>
  CustomFeedback?: any
}

const DigitalHuman: React.FC<DigitalHumanProps> = ({
  assets,
  onTimedOut,
  onSessionEnded,
  restart,
  postInit,
  config,
  token,
  loadingTips,
  CustomFeedback
}) => {
  const {
    video: { permissions },
    audio
  } = assets

  return (
    <UneeqProvider
      config={config}
      token={token}
      postInit={postInit}
      onTimedOut={onTimedOut}
      onSessionEnded={onSessionEnded}
    >
      <DigitalHumanContent
        CustomFeedback={CustomFeedback}
        restart={restart}
        audio={audio}
        permissions={permissions}
        loadingTips={loadingTips}
      />
    </UneeqProvider>
  )
}

export default DigitalHuman
