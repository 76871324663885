import React, { useContext } from 'react'
import { useUneeqState, UneeqContext } from 'uneeq-react-core'
import Information from './Information'
import Action from './Action'
import { Box } from 'rebass'
import { motion, AnimatePresence } from 'framer-motion'
import InformationExpanded from './InformationExpanded'
import styles from './styles'
import { useMediaQuery } from 'react-responsive'
import Config from 'digital-human/src/config'

const MotionCard = motion.custom(Box)

const stylesDiana = {
  conatianerMobileDiana: {
    label: 'onscreen-info',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
    flexDirection: 'column',
    justifyContent: 'center',
    left: 0,
    zIndex: [4, 4, 4, 4, 1, 1]
  }
}

const OnScreenInfo = () => {
  const { hiddenUI, onScreenInfo, mobileInformationOpen } = useUneeqState()
  const buttonEventIsUndefined =
    typeof onScreenInfo.information?.event === 'undefined'

  const mediaQuerySmallDevices = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const { state } = useContext(UneeqContext)
  const config = Config.getInstance()
  return (
    <>
      {onScreenInfo.action && <Action action={onScreenInfo.action} />}
      {config.persona.name === 'Diana' ? (
        <Box
          sx={
            mediaQuerySmallDevices
              ? {
                  ...stylesDiana.conatianerMobileDiana,
                  display:
                    hiddenUI || !mobileInformationOpen
                      ? ['none', 'flex', 'flex', 'flex', 'flex', 'flex']
                      : 'flex'
                }
              : {
                  ...styles.conatianer,
                  display:
                    hiddenUI || !mobileInformationOpen
                      ? ['none', 'flex', 'flex', 'flex', 'flex', 'flex']
                      : 'flex'
                }
          }
          id="onscreeninfo-container"
        >
          <Information information={onScreenInfo.information} />
        </Box>
      ) : (
        <Box
          sx={
            mediaQuerySmallDevices && buttonEventIsUndefined
              ? {
                  ...styles.conatianerMobile,
                  backdropFilter:
                    state.mediaActive || !state.avatarSpeaking
                      ? [
                          'blur(1.188rem)',
                          'blur(1.188rem)',
                          'blur(1.188rem)',
                          'none',
                          'none'
                        ]
                      : '',
                  display:
                    // hiddenUI || !mobileInformationOpen
                    //   ? ['none', 'flex', 'flex', 'flex', 'flex', 'flex']
                    //   :
                    'flex'
                }
              : {
                  ...styles.conatianer,
                  display:
                    // hiddenUI || !mobileInformationOpen
                    //   ? ['none', 'flex', 'flex', 'flex', 'flex', 'flex']
                    //   :
                    'flex'
                }
          }
          id="onscreeninfo-container"
        >
          <Information information={onScreenInfo.information} />
        </Box>
      )}
    </>
  )
}

export default OnScreenInfo
