import { Flex } from 'rebass'
import { Slider } from 'antd'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'
import React, { useState, useContext } from 'react'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import { ReactComponent as SubtitlesNone } from '../../assets/img/subtitlesNone.svg'
import { ReactComponent as SubtitlesOn } from '../../assets/img/subtitlesOn.svg'
import Config from 'digital-human/src/config'

//Component used to test html in Home instead of need wait, used just for development
const CaptionSlider: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { dispatch } = useContext(UneeqContext)
  const { captionVisible } = useUneeqState()

  const config = Config.getInstance()

  const [caption, setCaption] = useState(captionVisible ? 94 : 5)
  const formatter = value => {
    switch (value) {
      case 94:
        return t('Menu.subtitle')
    }
  }

  const sliderHandleStyle = {
    '& div.ant-slider-handle': {
      backgroundColor: config.persona.style.colors.buttons.active['background'],
      border: '0.125rem solid #fff'
    },
    '& .ant-slider:hover .ant-slider-track': {
      backgroundColor: '#fff'
    },
    '& span.ant-slider-dot-active': {
      borderColor: '#fff'
    },
    '& div.ant-slider-step': {
      boxShadow: 'inset 0px 0.063rem 0.125rem rgba(0, 0, 0, 0.25)'
    },
    '& .ant-slider-track': {
      backgroundColor: '#fff'
    },
    '& span.ant-slider-dot': {
      top: '-0.188rem',
      width: '0.75rem',
      height: '0.75rem',
      boxShadow: '0px 0.063rem 0.125rem rgba(0, 0, 0, 0.25)'
    },
    '& div.ant-slider-handle.ant-tooltip-open': {
      border: '0.125rem solid #fff'
    },
    '& .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open)': {
      border: '0.125rem solid #fff'
    },
    '& div.ant-slider-mark': {
      marginTop: '0.625rem'
    }
  }

  const marks = {
    5: <SubtitlesNone style={{ width: '1rem' }} />,
    94: <SubtitlesOn style={{ width: '1.5rem' }} />
  }

  return (
    <Flex sx={{ width: '12.5rem', ...sliderHandleStyle }}>
      <Slider
        dots
        style={{ width: '100%' }}
        onAfterChange={value =>
          dispatch({ type: 'captionVisibility', payload: value != 5 })
        }
        tooltip={{ formatter }}
        marks={marks}
        max={94}
        min={5}
        onChange={value => setCaption(value)}
        step={null}
        value={caption}
      />
    </Flex>
  )
}

export default CaptionSlider
