import React, {
  createElement,
  useRef,
  useContext,
  useState,
  useLayoutEffect,
  useEffect
} from 'react'
// import ReactHtmlParser from 'react-html-parser'
import { Image, Link, Text, Heading, Button, Flex, Box } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import { debounce } from 'lodash'
import { styles as s } from './styles'
import { ReactComponent as BackIcon } from '../../assets/img/left-arrow.svg'
import { ReactComponent as PopUpIcon } from '../../assets/img/new-tab.svg'
// @ts-ignore
import marksy from 'marksy'
import Config from 'digital-human/src/config'
import { InformationItem } from './InformationTypes'
import { motion, AnimatePresence } from 'framer-motion'
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi'
import { useMediaQuery } from 'react-responsive'
import VideoPlayer from './VideoPlayer'

const MotionButton = motion.custom(Link)
const MotionFlex = motion.custom(Flex)
const mediaQueryMobile = useMediaQuery({
  query: '(max-width: 768px)'
})
const headingStyles = {
  fontSize: 2,
  fontWeight: 'bold',
  m: 0,
  p: 0
}

export const getUtteranceFromURI = (uri: string) => {
  const match = uri.match(/^say\:(.*)$/)
  console.log(match)
  if (!match) {
    return undefined
  }
  return match[1]
}

const iconSize = '1rem'
const iconColor = 'white'

const ExtraButtonContent = (props: any) => {
  switch (props.data?.toUpperCase()) {
    case 'ARROW':
      return (
        <BackIcon
          width={iconSize}
          height={iconSize}
          fill={iconColor}
          style={{ marginTop: '0.625rem' }}
        />
      )
    case 'POPUP':
      return (
        <PopUpIcon
          width={iconSize}
          height={iconSize}
          fill={iconColor}
          style={{ marginTop: '0.625rem' }}
        />
      )
    default:
      return null
  }
}
const compile = (
  markdown: string,
  send: (text: string) => void,
  sendCallback: (text: string) => void
) => {
  const parser = marksy({
    elements: {
      a: (props: any) => {
        const utterance = getUtteranceFromURI(props.href)
        return utterance ? (
          <a
            {...props}
            rel="noopener noreferrer"
            onClick={(event: Event) => {
              event.preventDefault()
              send(utterance)
              sendCallback(utterance)
            }}
          />
        ) : (
          <a {...props} rel="noopener noreferrer" />
        )
      },
      h1: ({ children }: any) => (
        <Heading sx={headingStyles}>{children}</Heading>
      ),
      h2: ({ children }: any) => (
        <Heading sx={headingStyles}>{children}</Heading>
      ),
      h3: ({ children }: any) => (
        <Heading sx={headingStyles}>{children}</Heading>
      ),
      h4: ({ children }: any) => (
        <Heading sx={headingStyles}>{children}</Heading>
      )
    },
    createElement
  })

  return parser(markdown, {})
}

const renderInformationItem = (item: InformationItem, index: number): any => {
  const config = Config.getInstance()
  const [hoveredButton, setHoveredButton] = useState<string | undefined>('')
  const [selectedButton, setSelectedButton] = useState<string | undefined>('')
  const { state, sendText, sendEvent, dispatch } = useContext(UneeqContext)
  const blocked = state.recording || state.avatarSpeaking || state.sending
  const vidRef = useRef<HTMLVideoElement>(null)
  let { speechMode } = useUneeqState()
  const styles = s.suggestedResponses
  const mediaQueryMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })
  const send = (response: string) => {
    sendEvent(response)
  }
  useLayoutEffect(() => {
    const handleVideoEnd = () => {
      console.log('Ended video')
      send('GO_NEXT')
    }
    if (vidRef.current) {
      vidRef.current.addEventListener('ended', handleVideoEnd)
      return () => vidRef?.current?.removeEventListener('ended', handleVideoEnd)
    }
  }, [vidRef.current])
  const blockStyle = blockParam => {
    if (blocked || blockParam) return { opacity: 0.5, '&:hover': {} }
    if (config.persona.name === 'Diana') {
      return {}
    }
    return {
      '&:hover': {
        ...config.persona.style.colors.buttons.active
      }
    }
  }

  const applyButtonStyling = (clicked, utilised, disabled) => {
    const blockedStyle = {
      opacity: blocked ? 0.5 : 1,
      cursor: 'auto !important'
    }
    const usedStyle = {
      pointerEvents: 'none'
    }
    const buttonStyle = config.persona.style.colors.buttons
    if (!clicked && !utilised) {
      if (blocked)
        return { '&:hover': { ...buttonStyle.basic }, ...blockedStyle }
      else if (disabled)
        return {
          '&:hover': { ...buttonStyle.basic },
          ...buttonStyle.basic,
          opacity: '0.5'
        }
      else
        return { '&:hover': { ...config.persona.style.colors.buttons.active } }
    } else {
      if (clicked) {
        return {
          '&:hover': { ...buttonStyle.active },
          ...buttonStyle.active,
          ...usedStyle
        }
      } else {
        return {
          '&:hover': { ...config.persona.style.colors.buttons.basic },
          ...buttonStyle.basic,
          ...usedStyle
        }
      }
    }
  }

  const buttonAction = (button: any) => {
    if (!blocked) {
      state.selectedButtonsId.push({
        id: button.id,
        groupId: button.groupId
      })
      return button.utterance
        ? sendText(button.utterance)
        : sendEvent(button.event)
    }
  }
  let { transcriptOpen, transcript } = useUneeqState()
  const scrollArea = useRef<HTMLElement>()
  useEffect(() => {
    if (scrollArea && scrollArea.current) {
      if (
        scrollArea?.current.scrollHeight - window.innerHeight * 0.7 >= 0 &&
        !transcriptOpen
      ) {
        scrollArea.current.style.setProperty(
          '-webkit-mask-image',
          'linear-gradient(to top, black 92%, transparent 100%),linear-gradient(to bottom, black 92%, transparent 100%)'
        )
        scrollArea.current.style.setProperty(
          'mask-image',
          'linear-gradient(to top, black 92%, transparent 100%),linear-gradient(to bottom, black 92%, transparent 100%)'
        )
        scrollArea.current.style.setProperty(
          '-webkit-mask-composite',
          'destination-in'
        )
        //scrollArea.current.style.setProperty('mask-composite', 'exclude')
      } else {
        scrollArea.current.style.setProperty('-webkit-mask-image', '')
        scrollArea.current.style.setProperty('mask-image', '')
        scrollArea.current.style.setProperty('-webkit-mask-composite', '')
        scrollArea.current.style.setProperty('mask-composite', '')
      }
    }
  }, [transcriptOpen, transcript, blocked])
  switch (item.type) {
    case 'html':
      console.warn('HTML type used')
      return null //ReactHtmlParser(item.html)
    case 'text':
      return <Text key={index}>{item.text}</Text>
    case 'event':
      if (!state.avatarSpeaking && !item.executed) {
        sendEvent(item.event)
        item.executed = true
      }
      return null
    case 'heading':
      return (
        <Text fontSize={3} key={index}>
          {item.text}
        </Text>
      )
    case 'markdown':
      const debouncedSend = useRef(
        debounce((text: string) => sendText(text), 2000)
      ).current
      const compiled = compile(item.markdown, debouncedSend, (text: string) => {
        dispatch({ type: 'suggestedResponseSent', payload: text })
      })
      return compiled.tree
    case 'list':
      return (
        <ul key={index}>
          {item.items.map((child, index) =>
            renderInformationItem(child, index)
          )}
        </ul>
      )
    case 'link':
      return (
        <Link href={item.href} rel="external" target="_blank" key={index}>
          <Text>{item.label}</Text>
        </Link>
      )
    case 'image':
      if (transcriptOpen)
        return (
          <Flex
            sx={{
              background: item.background ? item.background : 'white',
              width: 'auto',
              height: 'auto',
              justifyContent: 'center',
              minWidth: '12.5rem'
            }}
          >
            <Image
              src={item.source}
              alt={item.label}
              key={item.index}
              sx={{
                ...s.informationExpanded,
                maxWidth:
                  item.type?.toLowerCase() == 'mediatext' ? '100%' : '15rem',
                right: '0px',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => {
                {
                  dispatch({
                    type: 'openGallery',
                    payload: { galleryIndex: item.index, mode: 'image' }
                  })
                }
              }}
            />
          </Flex>
        )
      else
        return (
          <Flex
            sx={{
              width: 'auto',
              height: 'auto'
            }}
          >
            <Image
              src={item.source}
              alt={item.label}
              key={item.index}
              sx={{
                ...s.informationExpanded,
                background: item.background ? item.background : '',
                maxWidth: '20rem',
                height: 'auto',
                maxHeight: '20rem',
                marginLeft: mediaQueryMobile ? '' : 'auto',
                margin: mediaQueryMobile ? 'auto' : '',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => {
                {
                  dispatch({
                    type: 'openGallery',
                    payload: { galleryIndex: item.index, mode: 'image' }
                  })
                }
              }}
            />
          </Flex>
        )
    case 'buttons':
      if (!mediaQueryMobile)
        return (
          <MotionFlex
            sx={{
              ...styles.itemsContainer,
              maxHeight: transcriptOpen
                ? '100%'
                : ['70vh', '70vh', '70vh', '100%']
            }}
            key={index}
          >
            {item.buttons.map((b, indexB) => {
              const buttonsStyle = config.persona.style.colors.buttons
              return b.label == 'GO_BACK' || b.label === 'GO_NEXT' ? (
                <div key={indexB}></div>
              ) : config.persona.name === 'Diana' ? (
                <MotionButton
                  sx={{
                    ...styles.itemDiana,
                    ...(b.style == 'no_button' || b.label == 'no_button'
                      ? hoveredButton !== 'no_button' &&
                        hoveredButton !== '' &&
                        hoveredButton !== undefined
                        ? {
                            ...config.persona.style.colors.buttons.no_button,
                            backgroundColor:
                              buttonsStyle.no_button['background'],
                            opacity: 0.5
                          }
                        : {
                            ...config.persona.style.colors.buttons.no_button,
                            backgroundColor:
                              buttonsStyle.no_button['background']
                          }
                      : b.style == 'little_button' || b.label == 'little_button'
                      ? hoveredButton !== 'little_button' &&
                        hoveredButton !== '' &&
                        hoveredButton !== undefined
                        ? {
                            ...config.persona.style.colors.buttons
                              .little_button,
                            opacity: 0.5
                          }
                        : {
                            ...config.persona.style.colors.buttons.little_button
                          }
                      : b.style == 'significantly_button' ||
                        b.label == 'significantly_button'
                      ? hoveredButton !== 'significantly_button' &&
                        hoveredButton !== '' &&
                        hoveredButton !== undefined
                        ? {
                            ...config.persona.style.colors.buttons
                              .significant_button,
                            opacity: 0.5
                          }
                        : {
                            ...config.persona.style.colors.buttons
                              .significant_button
                          }
                      : {
                          ...config.persona.style.colors.buttons.basic,
                          '&:hover': {
                            filter: 'brightness(150%)'
                          }
                        }),
                    ...blockStyle(
                      !state.selectedButtonsId
                        .map(arg => arg.id)
                        .includes(b.id) &&
                        state.selectedButtonsId
                          .map(arg => arg.groupId)
                          .includes(b.groupId)
                    )
                  }}
                  value={b.utterance ? b.utterance : b.event}
                  id={`${b.id}`}
                  key={indexB}
                  onClick={e => {
                    setSelectedButton(b.style ? b.style : b.label)
                    setTimeout(() => {
                      state.selectedButtonsId
                        .map(arg => arg.groupId)
                        .includes(b.groupId)
                        ? console.info('Group already clicked')
                        : buttonAction(b)
                    }, 500)
                  }}
                  onMouseOver={() =>
                    setTimeout(() => {
                      setHoveredButton(b.style ? b.style : b.label)
                    }, 100)
                  }
                  onMouseOut={() =>
                    setTimeout(() => {
                      setHoveredButton('')
                    }, 100)
                  }
                >
                  <Flex
                    sx={
                      b.style === 'significantly_button' ||
                      b.style === 'little_button' ||
                      b.style === 'no_button' ||
                      b.label === 'significantly_button' ||
                      b.label === 'little_button' ||
                      b.label === 'no_button'
                        ? {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '200px',
                            cursor: 'pointer'
                          }
                        : b.label && b.label.length > 20 && !mediaQueryMobile
                        ? {
                            width: '400px'
                          }
                        : {}
                    }
                  >
                    <Text
                      sx={
                        b.label && b.label.length > 20
                          ? { textAlign: 'left' }
                          : {}
                      }
                      htmlFor={b.label}
                    >
                      {b.utterance ? b.utterance : b.label}
                    </Text>
                    {b.style === 'significantly_button' ||
                    b.style === 'little_button' ||
                    b.style === 'no_button' ||
                    b.label === 'significantly_button' ||
                    b.label === 'little_button' ||
                    b.label === 'no_button' ? (
                      selectedButton === b.label ||
                      selectedButton === b.style ? (
                        <Flex>
                          <BiRadioCircleMarked />
                        </Flex>
                      ) : (
                        <Flex>
                          <BiRadioCircle />
                        </Flex>
                      )
                    ) : (
                      ''
                    )}
                  </Flex>
                </MotionButton>
              ) : (
                <Flex sx={{ ...styles.flexButtonContainer }}>
                  <ExtraButtonContent data={b.icon} />
                  <MotionButton
                    sx={
                      config.persona.features.differentChatStyle
                        ? {
                            ...styles.item,
                            ...config.persona.style.colors.buttons.basic,
                            ...applyButtonStyling(
                              state.selectedButtonsId
                                .map(arg => arg.id)
                                .includes(b.id),
                              state.selectedButtonsId
                                .map(arg => arg.groupId)
                                .includes(b.groupId),
                              b.disabled
                            )
                          }
                        : {
                            ...styles.item,
                            ...config.persona.style.colors.buttons.basic,
                            ...applyButtonStyling(
                              state.selectedButtonsId
                                .map(arg => arg.id)
                                .includes(b.id),
                              state.selectedButtonsId
                                .map(arg => arg.groupId)
                                .includes(b.groupId),
                              b.disabled
                            )
                          }
                    }
                    value={b.utterance ? b.utterance : b.event}
                    id={`${b.id}`}
                    key={indexB}
                    href={b.link}
                    target="_blank"
                    onClick={e => {
                      const inactive = state.selectedButtonsId
                        .map(arg => arg.groupId)
                        .includes(b.groupId)
                      if (!b.link || inactive || blocked) e.preventDefault()
                      inactive
                        ? console.info('Group already clicked')
                        : buttonAction(b)
                    }}
                  >
                    {b.label}
                  </MotionButton>
                </Flex>
              )
            })}
          </MotionFlex>
        )
      else if (config.persona.name == 'Diana')
        return (
          <MotionFlex
            sx={{
              ...styles.itemsContainer,
              maxHeight: transcriptOpen
                ? '100%'
                : ['70vh', '70vh', '70vh', '100%']
            }}
            key={index}
          >
            {item.buttons.map((b, indexB) => {
              const buttonsStyle = config.persona.style.colors.buttons
              return b.label == 'GO_BACK' || b.label === 'GO_NEXT' ? (
                <div key={indexB}></div>
              ) : (
                <MotionButton
                  sx={{
                    ...styles.itemDiana,
                    ...(b.style == 'no_button' || b.label == 'no_button'
                      ? hoveredButton !== 'no_button' &&
                        hoveredButton !== '' &&
                        hoveredButton !== undefined
                        ? {
                            ...config.persona.style.colors.buttons.no_button,
                            backgroundColor:
                              buttonsStyle.no_button['background'],
                            opacity: 0.5
                          }
                        : {
                            ...config.persona.style.colors.buttons.no_button,
                            backgroundColor:
                              buttonsStyle.no_button['background']
                          }
                      : b.style == 'little_button' || b.label == 'little_button'
                      ? hoveredButton !== 'little_button' &&
                        hoveredButton !== '' &&
                        hoveredButton !== undefined
                        ? {
                            ...config.persona.style.colors.buttons
                              .little_button,
                            opacity: 0.5
                          }
                        : {
                            ...config.persona.style.colors.buttons.little_button
                          }
                      : b.style == 'significantly_button' ||
                        b.label == 'significantly_button'
                      ? hoveredButton !== 'significantly_button' &&
                        hoveredButton !== '' &&
                        hoveredButton !== undefined
                        ? {
                            ...config.persona.style.colors.buttons
                              .significant_button,
                            opacity: 0.5
                          }
                        : {
                            ...config.persona.style.colors.buttons
                              .significant_button
                          }
                      : { ...config.persona.style.colors.buttons.basic }),
                    ...blockStyle(
                      !state.selectedButtonsId
                        .map(arg => arg.id)
                        .includes(b.id) &&
                        state.selectedButtonsId
                          .map(arg => arg.groupId)
                          .includes(b.groupId)
                    )
                  }}
                  value={b.utterance ? b.utterance : b.event}
                  id={`${b.id}`}
                  key={indexB}
                  onClick={e => {
                    setSelectedButton(b.style ? b.style : b.label)
                    setTimeout(() => {
                      state.selectedButtonsId
                        .map(arg => arg.groupId)
                        .includes(b.groupId)
                        ? console.info('Group already clicked')
                        : buttonAction(b)
                    }, 500)
                  }}
                  onMouseOver={() =>
                    setTimeout(() => {
                      setHoveredButton(b.style ? b.style : b.label)
                    }, 100)
                  }
                  onMouseOut={() =>
                    setTimeout(() => {
                      setHoveredButton('')
                    }, 100)
                  }
                >
                  <Flex
                    sx={
                      b.style === 'significantly_button' ||
                      b.style === 'little_button' ||
                      b.style === 'no_button' ||
                      b.label === 'significantly_button' ||
                      b.label === 'little_button' ||
                      b.label === 'no_button'
                        ? {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '200px',
                            cursor: 'pointer'
                          }
                        : b.label && b.label.length > 20 && !mediaQueryMobile
                        ? {
                            width: '400px'
                          }
                        : {}
                    }
                  >
                    <Text
                      sx={
                        b.label && b.label.length > 20
                          ? { textAlign: 'left' }
                          : {}
                      }
                      htmlFor={b.label}
                    >
                      {b.utterance ? b.utterance : b.label}
                    </Text>
                    {b.style === 'significantly_button' ||
                    b.style === 'little_button' ||
                    b.style === 'no_button' ||
                    b.label === 'significantly_button' ||
                    b.label === 'little_button' ||
                    b.label === 'no_button' ? (
                      selectedButton === b.label ||
                      selectedButton === b.style ? (
                        <Flex>
                          <BiRadioCircleMarked />
                        </Flex>
                      ) : (
                        <Flex>
                          <BiRadioCircle />
                        </Flex>
                      )
                    ) : (
                      ''
                    )}
                  </Flex>
                </MotionButton>
              )
            })}
          </MotionFlex>
        )
      else
        return (
          <Flex
            ref={scrollArea}
            sx={{ ...styles.scrollArea, overflowX: 'hidden' }}
          >
            <MotionFlex
              sx={{
                ...styles.itemsContainer,
                maxHeight: transcriptOpen
                  ? '100%'
                  : ['70vh', '70vh', '70vh', '100%'],
                pt: !transcriptOpen ? '2rem' : '10px',
                pb: !transcriptOpen ? '2rem' : '10px',
                '& > :last-child': {
                  '& > *': {
                    mb: transcriptOpen ? '' : '5rem !important'
                  }
                }
              }}
              key={index}
            >
              {item.buttons.map((b, indexB) => {
                const buttonsStyle = config.persona.style.colors.buttons
                return b.label == 'GO_BACK' || b.label === 'GO_NEXT' ? null : (
                  <Flex sx={{ ...styles.flexButtonContainer }}>
                    <ExtraButtonContent data={b.icon} />
                    <MotionButton
                      sx={{
                        ...styles.item,
                        ...config.persona.style.colors.buttons.basic,
                        ...applyButtonStyling(
                          state.selectedButtonsId
                            .map(arg => arg.id)
                            .includes(b.id),
                          state.selectedButtonsId
                            .map(arg => arg.groupId)
                            .includes(b.groupId),
                          b.disabled
                        )
                      }}
                      value={b.utterance ? b.utterance : b.event}
                      id={`${b.id}`}
                      key={indexB}
                      href={b.link}
                      target="_blank"
                      onClick={e => {
                        const inactive = state.selectedButtonsId
                          .map(arg => arg.groupId)
                          .includes(b.groupId)
                        if (!b.link || inactive || blocked) e.preventDefault()
                        inactive
                          ? console.info('Group already clicked')
                          : buttonAction(b)
                      }}
                    >
                      {b.label}
                    </MotionButton>
                  </Flex>
                )
              })}
            </MotionFlex>
          </Flex>
        )
    case 'video':
      if (!transcriptOpen) {
        return <VideoPlayer key={item.index} index={item.index} />
      } else {
        return (
          <Flex
            sx={{
              maxWidth: '100%'
            }}
          >
            <VideoPlayer key={item.index} index={item.index} />
          </Flex>
        )
      }
  }
}

interface InformationProps {
  information: InformationItem[]
}

const InformationContent: React.FC<InformationProps> = ({ information }) => {
  if (!information?.length) return null
  return (
    <>
      {information.map((item: InformationItem, index: number) => {
        return renderInformationItem(item, index)
      })}
    </>
  )
}

export default InformationContent
